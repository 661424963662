import React from 'react'
import nutrihog from '../assets/images/nutrihogs-logo.png'
import {NavLink} from 'react-router-dom'

const Header = () => {
    return (
        <div>
            
            <header className="fixed-top">
                <nav className="navbar navbar-expand-md navbar-light shadow">
                    <div className="container">
                        <a className="navbar-brand p-0 me-2" href="/">
                            <img src={nutrihog} alt="NUTRIHOGS CORPORATION" className="img-fluid header-logo" />
                        </a>
                        <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent"
                            aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                            <span className="navbar-toggler-icon"></span>
                        </button>
                        <div className="collapse navbar-collapse" id="navbarSupportedContent">
                            <ul className="navbar-nav ms-md-auto">
                                {/* <li className="nav-item">
                                    <a className="nav-link" href="/">HOME <span className="sr-only">(current)</span></a>
                                </li>
                                <li className="nav-item">
                                    <a className="nav-link" href="/about">ABOUT US</a>
                                </li>
                                <li className="nav-item">
                                    <a className="nav-link" href="/contact">CONTACT US</a>
                                </li>
                                <button>BOOK NOW</button> */}

                                <li className="nav-item p-1">
                                    <NavLink exact to="/" activeClassName="main-nav-active" className="navlink-text d-grid #form-control nav-top">
                                        HOME
                                    </NavLink>
                                </li>
                                <li className="nav-item p-1">
                                    <NavLink exact to="/about"  activeClassName="main-nav-active" className="navlink-text d-grid #form-control">
                                        ABOUT
                                    </NavLink>
                                </li>
                                <li className="nav-item p-1">
                                    <NavLink exact to="/contact"  activeClassName="main-nav-active" className="navlink-text d-grid #form-control">
                                        CONTACT US
                                    </NavLink>
                                </li>
                            </ul>
                        </div>
                    </div>
                </nav>
            </header>
        </div>
    )
}

export default Header
