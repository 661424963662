import React from 'react'
import nutrihog from '../assets/images/nutrihogs-logo.png'
import news1 from '../assets/images/footer-news1.png'

const Footer = () => {
    return (
        <footer>
            {/* for Desktop Footer */}
            <div className="desktop-hide">
                <div className="container-fluid footer-submain-bg">
                    <div className="row">
                        <div className="col-md-3">
                            <img src={nutrihog} className="img-fluid footer-logo" alt="Nutrihogs"/>
                            <p className="footer-content">
                                The point of using <strong className="footer-highlight">Lorem Ipsum</strong> is that it has a more-or-less normal distribution of letters,
                                as opposed to using 'Content here, Content here, it look like readable English.
                            </p>
                        </div>
                        <div className="col-md-3">
                            <h1 className="footer-title">News</h1>
                            <div className="row mt-4">
                                <div className="col-md-3">
                                    <img src={news1} className="img-fluid" alt="Nutrihogs"/>
                                </div>
                                <div className="col-md-9">
                                    <h4 className="footer-sub-title">March 05, 2021</h4>
                                    <p className="news-content">
                                        The point of using Lorem Ipsum is that it has a more-or-less normal distribution of letters.
                                    </p>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-md-3">
                                    <img src={news1} className="img-fluid" alt="Nutrihogs"/>
                                </div>
                                <div className="col-md-9">
                                    <h4 className="footer-sub-title">March 05, 2021</h4>
                                    <p className="news-content">
                                        The point of using Lorem Ipsum is that it has a more-or-less normal distribution of letters.
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-3">
                            <h1 className="footer-title">Services</h1>
                            <div className="column mt-4">
                                <p className="service-content">&#62; Agricultural Lands</p>
                                <p className="service-content">&#62; Cross-breeding and Pasture of Pigs, Cows, Goats and other similar stocks.</p>
                                <p className="service-content">&#62; Feedmill Operations</p>
                                <p className="service-content">&#62; Trading and Distributions of livestock</p>
                            </div>
                        </div>
                        <div className="col-md-3">
                            <h1 className="footer-title">Contact</h1>
                            <div className="column mt-4">
                                <p className="service-content">&#62; +63 909 090 0920</p>
                                <p className="service-content">&#62; (083) 000 - 0000</p>
                                <p className="service-content">&#62; info@nutrihog.com.ph</p>
                            </div>
                            <div className="column">
                                <i className="fab fa-facebook fa-2x footer-icon"></i>
                                <i className="fab fa-twitter fa-2x footer-icon"></i>
                                <i className="fab fa-instagram fa-2x footer-icon"></i>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            {/* for IPAd Footer */}
            <div className="ipad-hide">
                <div className="container-fluid footer-submain-bg">
                    <div className="row">
                        <div className="col-md-6 p-4">
                            <img src={nutrihog} className="img-fluid footer-logo" alt="Nutrihogs"/>
                            <p className="footer-content">
                                The point of using <strong className="footer-highlight">Lorem Ipsum</strong> is that it has a more-or-less.,
                                as opposed to using 'Content here' it look like readable English.
                            </p>
                        </div>
                        <div className="col-md-6 p-4">
                            <h1 className="footer-title">News</h1>
                            <div className="row mt-4">
                                <div className="col-md-3">
                                    <img src={news1} className="img-fluid" alt="Nutrihogs"/>
                                </div>
                                <div className="col-md-9">
                                    <h4 className="footer-sub-title">March 05, 2021</h4>
                                    <p className="news-content">
                                        The point of using Lorem Ipsum is that it has a more-or-less normal distribution of letters.
                                    </p>
                                </div>
                            </div>
                            <div className="row mt-3">
                                <div className="col-md-3">
                                    <img src={news1} className="img-fluid" alt="Nutrihogs"/>
                                </div>
                                <div className="col-md-9">
                                    <h4 className="footer-sub-title">March 05, 2021</h4>
                                    <p className="news-content">
                                        The point of using Lorem Ipsum is that it has a more-or-less normal distribution of letters.
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-6 p-4">
                            <h1 className="footer-title">Services</h1>
                            <div className="column mt-4">
                                <p className="service-content">&#62; Agricultural Lands</p>
                                <p className="service-content">&#62; Cross-breeding and Pasture of Pigs, Cows, Goats and other similar stocks.</p>
                                <p className="service-content">&#62; Feedmill Operations</p>
                                <p className="service-content">&#62; Trading and Distributions of livestock</p>
                            </div>
                        </div>
                        <div className="col-md-6 p-4">
                            <h1 className="footer-title">Contact</h1>
                            <div className="column mt-4">
                                <p className="service-content">&#62; +63 909 090 0920</p>
                                <p className="service-content">&#62; (083) 000 - 0000</p>
                                <p className="service-content">&#62; info@nutrihog.com.ph</p>
                            </div>
                            <div className="column">
                                <i className="fab fa-facebook fa-2x footer-icon-fb"></i>
                                <i className="fab fa-twitter fa-2x footer-icon"></i>
                                <i className="fab fa-instagram fa-2x footer-icon"></i>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            {/* for Mobile Footer */}
            <div className="mobile-hide">
                <div className="container-fluid footer-submain-bg">
                    <div className="row">
                        <div className="col-md-3">
                            <img src={nutrihog} className="img-fluid footer-logo" alt="Nutrihogs"/>
                            <p className="footer-content">
                                The point of using <strong className="footer-highlight">Lorem Ipsum</strong> is that it has a more-or-less normal distribution of letters,
                                as opposed to using 'Content here, Content here, it look like readable English.
                            </p>
                        </div>
                        <div className="col-md-3">
                            <h1 className="footer-title">News</h1>
                            <div className="row mt-4">
                                <div className="col-md-3">
                                    <img src={news1} className="img-fluid" alt="Nutrihogs"/>
                                </div>
                                <div className="col-md-9">
                                    <h4 className="footer-sub-title">March 05, 2021</h4>
                                    <p className="news-content">
                                        The point of using Lorem Ipsum is that it has a more-or-less normal distribution of letters.
                                    </p>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-md-3">
                                    <img src={news1} className="img-fluid" alt="Nutrihogs"/>
                                </div>
                                <div className="col-md-9">
                                    <h4 className="footer-sub-title">March 05, 2021</h4>
                                    <p className="news-content">
                                        The point of using Lorem Ipsum is that it has a more-or-less normal distribution of letters.
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-3">
                            <h1 className="footer-title">Services</h1>
                            <div className="column mt-4">
                                <p className="service-content">&#62; Agricultural Lands</p>
                                <p className="service-content">&#62; Cross-breeding and Pasture of Pigs, Cows, Goats and other similar stocks.</p>
                                <p className="service-content">&#62; Feedmill Operations</p>
                                <p className="service-content">&#62; Trading and Distributions of livestock</p>
                            </div>
                        </div>
                        <div className="col-md-3">
                            <h1 className="footer-title">Contact</h1>
                            <div className="column mt-4">
                                <p className="service-content">&#62; +63 909 090 0920</p>
                                <p className="service-content">&#62; (083) 000 - 0000</p>
                                <p className="service-content">&#62; info@nutrihog.com.ph</p>
                            </div>
                            <div className="column">
                                <i className="fab fa-facebook fa-2x footer-icon"></i>
                                <i className="fab fa-twitter fa-2x footer-icon"></i>
                                <i className="fab fa-instagram fa-2x footer-icon"></i>
                            </div>
                        </div>
                    </div>
                </div>
            </div>


            <div className="container-fluid footer-main-bg">
                <div className="row">
                    <div className="col-md-12">
                        <p className="footer-main-text">
                            Copyright © 2021 Classify Inc. www. Classify.com.ph
                        </p>
                    </div>
                </div>
            </div>


        </footer>
    )
}

export default Footer
