import React from 'react'
import landingImage from '../assets/images/carousel-img1.png'

import statimage from '../assets/images/statistic-img.png'
import img1 from '../assets/images/Gallery/image1.jpg'
import img4 from '../assets/images/Gallery/image4.jpg'
import img5 from '../assets/images/Gallery/image5.jpg'


import classify from '../assets/images/classify.png'
import ultra from '../assets/images/ultra.png'
import gfi from '../assets/images/gfi.png'
import cpf from '../assets/images/cpf.png'
import ultramart from '../assets/images/ultramart.png'
import rr from '../assets/images/rr.png'


function IndexContent() {
    return (
        <div>
            {/* ----------------------------------------------------------------------------------------- */}
            
            {/* LANDING IMAGE */}
            <div className="desktop-hide">
                <div id="carouselExampleControls" className="carousel slide" data-ride="carousel">
                    <div className="carousel-inner">
                        <div className="carousel-item active">
                            <img src={landingImage} className="img-fluid d-block w-100" alt="First slide"/>
                            <div className="carousel-caption">
                                <p className="carousel-txt1">A New Way to Invest in Agriculture</p>
                                <p className="carousel-txt2">Experience The Real</p>
                                <p className="carousel-txt3">Farming</p>
                                <button className="carousel-btn">READ MORE</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="ipad-hide">
                <div id="carouselExampleControls" className="carousel slide" data-ride="carousel">
                    <div className="carousel-inner">
                        <div className="carousel-item active">
                            <img src={landingImage} className="img-fluid d-block w-100" alt="First slide"/>
                            <div className="carousel-caption">
                                <p className="carousel-txt1 c-txt1">A New Way to Invest in Agriculture</p>
                                <p className="carousel-txt2 c-txt2">Experience The Real</p>
                                <p className="carousel-txt3 c-txt3">Farming</p>
                                <button className="carousel-btn">READ MORE</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="mobile-hide">
                <div id="carouselExampleControls" className="carousel slide" data-ride="carousel">
                    <div className="carousel-inner">
                        <div className="carousel-item active">
                            <img src={landingImage} className="img-fluid d-block w-100" alt="First slide"/>
                            <div className="carousel-caption">
                                <p className="car-txt1">A New Way to Invest in Agriculture</p>
                                <p className="car-txt2">Experience The Real</p>
                                <p className="car-txt3">Farming</p>
                                <button className="carousel-btn">READ MORE</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            
            {/* statistics */}
            <div className="container-fluid">
                {/* Our Service */}
                <div className="container">
                    <div className="row">
                        <div className="col-md-12">
                            <p className="services-txt">Our Services</p>
                        </div>
                    </div>
                </div>

                {/* statistics */}
                <div className="container statistic">
                    <div className="row">
                        <div className="col-md-12">
                            <p className="statistic-txt"><span>Statistics</span></p>
                        </div>
                    </div>
                    
                    <div className="desktop-hide">
                        {/* for Desktop */}
                        <div className="row title-gap ">
                            <div className="col-md-4">
                                <div className="">
                                    <p className="stat-category"> Product</p>
                                    <p className="cat-value"> 10+</p>
                                </div>
                            </div>
                            <div className="col-md-4">
                                <div className="border-left">
                                    <p className="stat-category">Branches</p>
                                    <p className="cat-value"> 8+</p>
                                </div>
                            </div>
                            <div className="col-md-4">
                                <div className="border-left">
                                    <p className="stat-category">Partner’s</p>
                                    <p className="cat-value"> 70+</p>
                                </div>
                            </div>
                        </div>
                        <div className="row p-3">
                            <div className="col-md-3">
                                <img src={statimage} alt="Statistic images" className="img-fluid stat-img" />
                            </div>
                            <div className="col-md-3">
                                <p className="cat-value">90%</p>
                            </div>
                            <div className="col-md-6">
                                <p className="left-padding">
                                    The point of using Lorem Ipsum is that it has a more-or-less normal
                                    distribution of letters, as opposed to using 'Content here, content here',
                                    making it look like readable English. Many desktop publishing packages
                                    and web page editors now use Lorem Ipsum as their default model text,
                                    and a search for 'lorem ipsum' will uncover many web sites still in their
                                    infancy. Various versions have evolved over the years, sometimes by accident,
                                    sometimes on purpose (injected humour and the like).
                                </p>
                            </div>
                        </div>
                        {/* for Desktop view only */}
                    </div>

                    <div className="ipad-hide">
                        {/* for ipad */}
                        <div className="row title-gap">
                            <table>
                                <tr>
                                    <td>
                                        <div className="">
                                            <p className="stat-category"> Product</p>
                                            <p className="cat-value"> 10+</p>
                                        </div>
                                    </td>
                                    <td>
                                        <div className="border-left">
                                            <p className="stat-category">Branches</p>
                                            <p className="cat-value"> 8+</p>
                                        </div>
                                    </td>
                                    <td>
                                        <div className="border-left">
                                            <p className="stat-category">Partner’s</p>
                                            <p className="cat-value"> 70+</p>
                                        </div>
                                    </td>
                                </tr>
                            </table>
                        </div>
                        <div className="row p-3" >
                            <table>
                                <tr>
                                    <td>
                                        <img src={statimage} alt="Statistic images" className="img-fluid stat-img" />
                                    </td>
                                    <td>
                                        <p className="cat-value1">90%</p>
                                    </td>
                                </tr>
                                <tr>
                                    <td colSpan="2"> 
                                        <p>
                                            The point of using Lorem Ipsum is that it has a more-or-less normal
                                            distribution of letters, as opposed to using 'Content here, content here',
                                            making it look like readable English. Many desktop publishing packages
                                            and web page editors now use Lorem Ipsum as their default model text,
                                            and a search for 'lorem ipsum' will uncover many web sites still in their
                                            infancy. Various versions have evolved over the years, sometimes by accident,
                                            sometimes on purpose (injected humour and the like).
                                        </p>
                                    </td>
                                </tr>
                            </table>
                        </div>
                        {/* for ipad view only*/}
                    </div>

                    <div className="mobile-hide">
                        {/* for mobile */}
                        <div className="row title-gap ">
                            <table>
                                <tr>
                                    <td>
                                        <div className="">
                                            <p className="stat-category"> Product</p>
                                            <p className="cat-value"> 10+</p>
                                        </div>
                                    </td>
                                    <td>
                                        <div className="border-left">
                                            <p className="stat-category">Branches</p>
                                            <p className="cat-value"> 8+</p>
                                        </div>
                                    </td>
                                    <td>
                                        <div className="border-left">
                                            <p className="stat-category">Partner’s</p>
                                            <p className="cat-value"> 70+</p>
                                        </div>
                                    </td>
                                </tr>
                            </table>
                        </div>
                        <div className="row p-3">
                            <table>
                                <tr>
                                    <td>
                                        <img src={statimage} alt="Statistic images" className="img-fluid stat-img" />
                                    </td>
                                    <td>
                                        <p className="cat-value1">90%</p>
                                    </td>
                                </tr>
                                <tr>
                                    <td colSpan="2"> 
                                        <p>
                                            The point of using Lorem Ipsum is that it has a more-or-less normal
                                            distribution of letters, as opposed to using 'Content here, content here',
                                            making it look like readable English. Many desktop publishing packages
                                            and web page editors now use Lorem Ipsum as their default model text,
                                            and a search for 'lorem ipsum' will uncover many web sites still in their
                                            infancy. Various versions have evolved over the years, sometimes by accident,
                                            sometimes on purpose (injected humour and the like).
                                        </p>
                                    </td>
                                </tr>
                            </table>
                        </div>
                        {/* for mobile view only*/}
                    </div>
                </div>
            </div>

            {/* ----------------------------------------------------------------------------------------- */}




            {/* SERVICES Desktop*/}
            {/* ----------------------------------------------------------------------------------------- */}

            <div className="desktop-hide">
                {/* SERVICES Desktop*/}
                <div className="container-fluid div-padding">
                {/* <div className="row">
                    <div className="col-md-12">
                        <p className="statistic-txt"><span>Services</span></p>
                    </div>
                </div> */}

                {/* <div className="row title-gap">
                    <div className="col-md-12">
                        <div id="services-slide" className="carousel slide" data-bs-touch="false" data-bs-interval="false">
                            <div className="carousel-inner p-3">
                                <div className="carousel-item active">
                                    <div className="services-image1">
                                        <div className="services-bg">
                                            <p className="services-bg-title"> Farming </p>
                                        </div>
                                    </div>
                                    <div className="services-image2">
                                        <div className="services-bg">
                                            <p className="services-bg-title1"> Poultry </p>
                                        </div>
                                    </div>
                                    <div className="services-image3">
                                        <div className="services-bg">
                                            <p className="services-bg-title2"> Poultry </p>
                                        </div>
                                    </div>
                                    <div className="services-image4">
                                        <div className="services-bg">
                                            <p className="services-bg-title3"> Poultry </p>
                                        </div>
                                    </div>
                                    <div className="services-image6">
                                        <div className="services-bg">
                                            <p className="services-bg-title1"> Poultry </p>
                                        </div>
                                    </div>
                                    <div className="services-image5">
                                        <div className="services-bg">
                                            <p className="services-bg-title"> Poultry </p>
                                        </div>
                                    </div>
                                    <div className="services-image8">
                                        <div className="services-bg">
                                            <p className="services-bg-title3"> Poultry </p>
                                        </div>
                                    </div>
                                    <div className="services-image7">
                                        <div className="services-bg">
                                            <p className="services-bg-title2"> Poultry </p>
                                        </div>
                                    </div>
                                </div>
                                <div className="carousel-item">
                                    <div className="services-image1">
                                        <div className="services-bg">
                                            <p className="services-bg-title"> Farming </p>
                                        </div>
                                    </div>
                                    <div className="services-image2">
                                        <div className="services-bg">
                                            <p className="services-bg-title1"> Poultry </p>
                                        </div>
                                    </div>
                                    <div className="services-image3">
                                        <div className="services-bg">
                                            <p className="services-bg-title2"> Poultry </p>
                                        </div>
                                    </div>
                                    <div className="services-image4">
                                        <div className="services-bg">
                                            <p className="services-bg-title3"> Poultry </p>
                                        </div>
                                    </div>
                                    <div className="services-image6">
                                        <div className="services-bg">
                                            <p className="services-bg-title1"> Poultry </p>
                                        </div>
                                    </div>
                                    <div className="services-image5">
                                        <div className="services-bg">
                                            <p className="services-bg-title"> Poultry </p>
                                        </div>
                                    </div>
                                    <div className="services-image8">
                                        <div className="services-bg">
                                            <p className="services-bg-title3"> Poultry </p>
                                        </div>
                                    </div>
                                    <div className="services-image7">
                                        <div className="services-bg">
                                            <p className="services-bg-title2"> Poultry </p>
                                        </div>
                                    </div>
                                </div>

                                <div className="carousel-item">
                                    <div className="services-image1">
                                        <div className="services-bg">
                                            <p className="services-bg-title"> Farming </p>
                                        </div>
                                    </div>
                                    <div className="services-image2">
                                        <div className="services-bg">
                                            <p className="services-bg-title1"> Poultry </p>
                                        </div>
                                    </div>
                                    <div className="services-image3">
                                        <div className="services-bg">
                                            <p className="services-bg-title2"> Poultry </p>
                                        </div>
                                    </div>
                                    <div className="services-image4">
                                        <div className="services-bg">
                                            <p className="services-bg-title3"> Poultry </p>
                                        </div>
                                    </div>
                                    <div className="services-image6">
                                        <div className="services-bg">
                                            <p className="services-bg-title1"> Poultry </p>
                                        </div>
                                    </div>
                                    <div className="services-image5">
                                        <div className="services-bg">
                                            <p className="services-bg-title"> Poultry </p>
                                        </div>
                                    </div>
                                    <div className="services-image8">
                                        <div className="services-bg">
                                            <p className="services-bg-title3"> Poultry </p>
                                        </div>
                                    </div>
                                    <div className="services-image7">
                                        <div className="services-bg">
                                            <p className="services-bg-title2"> Poultry </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <br/><br/>
                            <div className="carousel-indicators">
                                <button type="button" className="services-indicators active" data-bs-target="#services-slide" data-bs-slide-to="0" aria-current="true" aria-label="Slide 1"></button>
                                <button type="button" className="services-indicators" data-bs-target="#services-slide" data-bs-slide-to="1" aria-label="Slide 2"></button>
                                <button type="button" className="services-indicators" data-bs-target="#services-slide" data-bs-slide-to="2" aria-label="Slide 3"></button>
                            </div>
                        </div>
                    </div>
                </div> */}



                



                </div>
            </div>


            <div className="ipad-hide">
                {/* SERVICES for ipad*/}
                <div className="container-fluid div-padding">
                {/* <div className="row">
                    <div className="col-md-12">
                        <p className="statistic-txt"><span>Services</span></p>
                    </div>
                </div> */}

                <div className="row title-gap">
                    <div className="col-md-12">
                        {/* <div id="services2-slide" className="carousel slide" data-bs-touch="false" data-bs-interval="false">
                            <div className="carousel-inner p-3">
                                <div className="carousel-item active">
                                    <div className="services-image1">
                                        <div className="services-bg">
                                            <p className="services-bg-title"> Farming </p>
                                        </div>
                                    </div>
                                    <div className="services-image2">
                                        <div className="services-bg">
                                            <p className="services-bg-title1"> Poultry </p>
                                        </div>
                                    </div>
                                    <div className="services-image3">
                                        <div className="services-bg">
                                            <p className="services-bg-title3"> Poultry </p>
                                        </div>
                                    </div>
                                    <div className="services-image4">
                                        <div className="services-bg">
                                            <p className="services-bg-title3"> Poultry </p>
                                        </div>
                                    </div>
                                    <div className="services-image5">
                                        <div className="services-bg">
                                            <p className="services-bg-title"> Poultry </p>
                                        </div>
                                    </div>
                                    <div className="services-image6">
                                        <div className="services-bg">
                                            <p className="services-bg-title1"> Poultry </p>
                                        </div>
                                    </div>
                                </div>


                                <div className="carousel-item">
                                    <div className="services-image1">
                                        <div className="services-bg">
                                            <p className="services-bg-title"> Farming </p>
                                        </div>
                                    </div>
                                    <div className="services-image2">
                                        <div className="services-bg">
                                            <p className="services-bg-title1"> Poultry </p>
                                        </div>
                                    </div>
                                    <div className="services-image3">
                                        <div className="services-bg">
                                            <p className="services-bg-title3"> Poultry </p>
                                        </div>
                                    </div>
                                    <div className="services-image4">
                                        <div className="services-bg">
                                            <p className="services-bg-title3"> Poultry </p>
                                        </div>
                                    </div>
                                    <div className="services-image5">
                                        <div className="services-bg">
                                            <p className="services-bg-title"> Poultry </p>
                                        </div>
                                    </div>
                                    <div className="services-image6">
                                        <div className="services-bg">
                                            <p className="services-bg-title1"> Poultry </p>
                                        </div>
                                    </div>
                                    
                                </div>

                                <div className="carousel-item">
                                    <div className="services-image1">
                                        <div className="services-bg">
                                            <p className="services-bg-title"> Farming </p>
                                        </div>
                                    </div>
                                    <div className="services-image2">
                                        <div className="services-bg">
                                            <p className="services-bg-title1"> Poultry </p>
                                        </div>
                                    </div>
                                    <div className="services-image3">
                                        <div className="services-bg">
                                            <p className="services-bg-title3"> Poultry </p>
                                        </div>
                                    </div>
                                    <div className="services-image4">
                                        <div className="services-bg">
                                            <p className="services-bg-title3"> Poultry </p>
                                        </div>
                                    </div>
                                    <div className="services-image5">
                                        <div className="services-bg">
                                            <p className="services-bg-title"> Poultry </p>
                                        </div>
                                    </div>
                                    <div className="services-image6">
                                        <div className="services-bg">
                                            <p className="services-bg-title1"> Poultry </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <br/><br/><br/> */}
                            {/* <div className="carousel-indicators"> */}
                                {/* <button type="button" className="services-indicators active" data-bs-target="#services2-slide" data-bs-slide-to="0" aria-current="true" aria-label="Slide 1"></button>
                                <button type="button" className="services-indicators" data-bs-target="#services2-slide" data-bs-slide-to="1" aria-label="Slide 2"></button>
                                <button type="button" className="services-indicators" data-bs-target="#services2-slide" data-bs-slide-to="2" aria-label="Slide 3"></button> */}
                            
                                {/* <button className="carousel-control-prev5" type="button" data-bs-target="#services2-slide" data-bs-slide="prev">
                                    <i className="fas fa-arrow-left fa-lg"></i>
                                    <span className="visually-hidden">Previous</span>
                                </button> &emsp;&emsp; &emsp;&emsp; &emsp;&emsp; &emsp;&emsp;
                                <button className="carousel-control-next5" type="button" data-bs-target="#services2-slide" data-bs-slide="next">
                                    <i className="fas fa-arrow-right fa-lg"></i>
                                    <span className="visually-hidden">Next</span>
                                </button>
                            </div>
                        </div> */}
                    </div>
                </div>
            </div>
            </div>

            <div className="mobile-hide">
                {/* SERVICES Mobile*/}
                {/* <div className="container-fluid div-padding mobile-hide">
                <div className="row">
                    <div className="col-md-12">
                        <p className="statistic-txt"><span>Services</span></p>
                    </div>
                </div>

                <div className="row title-gap">
                    <div className="col-md-12">
                        <div id="services1-slide" className="carousel slide" data-bs-touch="false" data-bs-interval="false">
                            <div className="carousel-inner p-3">
                                <div className="carousel-item active">
                                    <div className="services-image1 mobile-img-plate1">
                                        <div className="services-bg1">
                                            <p className="services-bg-title"> Farming </p>
                                        </div>
                                    </div>
                                    <div className="services-image2 mobile-img-plate2 img-gap">
                                        <div className="services-bg2">
                                            <p className="services-bg-title1"> Poultry </p>
                                        </div>
                                    </div>
                                    
                                    <div className="services-image2 mobile-img-plate2">
                                        <div className="services-bg2">
                                            <p className="services-bg-title1"> Poultry </p>
                                        </div>
                                    </div>
                                    <div className="services-image1 mobile-img-plate1 img-gap">
                                        <div className="services-bg1">
                                            <p className="services-bg-title"> Farming </p>
                                        </div>
                                    </div>
                                </div>
                                
                                <div className="carousel-item">
                                    <div className="services-image1 mobile-img-plate1">
                                        <div className="services-bg1">
                                            <p className="services-bg-title"> Farming </p>
                                        </div>
                                    </div>
                                    <div className="services-image2 mobile-img-plate2 img-gap">
                                        <div className="services-bg2">
                                            <p className="services-bg-title1"> Poultry </p>
                                        </div>
                                    </div>
                                    
                                    <div className="services-image2 mobile-img-plate2">
                                        <div className="services-bg2">
                                            <p className="services-bg-title1"> Poultry </p>
                                        </div>
                                    </div>
                                    <div className="services-image1 mobile-img-plate1 img-gap">
                                        <div className="services-bg1">
                                            <p className="services-bg-title"> Farming </p>
                                        </div>
                                    </div>
                                </div>

                                <div className="carousel-item">
                                    <div className="services-image1 mobile-img-plate1">
                                        <div className="services-bg1">
                                            <p className="services-bg-title"> Farming </p>
                                        </div>
                                    </div>
                                    <div className="services-image2 mobile-img-plate2 img-gap">
                                        <div className="services-bg2">
                                            <p className="services-bg-title1"> Poultry </p>
                                        </div>
                                    </div>
                                    
                                    <div className="services-image2 mobile-img-plate2">
                                        <div className="services-bg2">
                                            <p className="services-bg-title1"> Poultry </p>
                                        </div>
                                    </div>
                                    <div className="services-image1 mobile-img-plate1 img-gap">
                                        <div className="services-bg1">
                                            <p className="services-bg-title"> Farming </p>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <button className="carousel-control-prev3" type="button" data-bs-target="#services1-slide" data-bs-slide="prev">
                                <i className="fas fa-arrow-left fa-lg"></i>
                                <span className="visually-hidden">Previous</span>
                            </button> &emsp;&emsp;
                            <button className="carousel-control-next3" type="button" data-bs-target="#services1-slide" data-bs-slide="next">
                                <i className="fas fa-arrow-right fa-lg"></i>
                                <span className="visually-hidden">Next</span>
                            </button>
                        </div>
                    </div>
                </div> */}

                
            {/* </div> */}
            </div>           
            {/* ----------------------------------------------------------------------------------------- */}




            {/* partners */}
            {/* ----------------------------------------------------------------------------------------- */}
            <div className="desktop-hide">
                {/* Partners for desktop*/}
                <div className="container partners-padding ">
                <div className="row">
                    <div className="col-md-12">
                        <p className="statistic-txt"><span>Partners</span></p>
                    </div>
                </div>
                <div className="row services-title-gap">
                    <div className="col-md-4 p-5">
                        <div className="card text-center parners-card">
                            <div className="card-body middle">
                                <img src={classify} className="img-fluid w-90 parners-logo" alt="..."/>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-4 p-5 sub-title-gap">
                        <div className="card text-center parners-card">
                            <div className="card-body middle">
                                <img src={ultra} className="img-fluid w-90 parners-logo" alt="..."/>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-4 p-5 sub-title-gap">
                        <div className="card text-center parners-card">
                            <div className="card-body middle">
                                <img src={gfi} className="img-fluid w-100 parners-logo" alt="..."/>
                            </div>
                        </div>
                    </div>     
                </div>
                <div className="row">
                    <div className="col-md-4 p-5 sub-title-gap">
                        <div className="card text-center parners-card">
                            <div className="card-body middle">
                                <img src={cpf} className="img-fluid parners-logo-cpf" alt="..."/>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-4 p-5 sub-title-gap">
                        <div className="card text-center parners-card">
                            <div className="card-body middle">
                                <img src={ultramart} className="img-fluid parners-logo" alt="..."/>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-4 p-5 sub-title-gap">
                        <div className="card text-center parners-card">
                            <div className="card-body middle">
                                <img src={rr} className="img-fluid w-50" alt="..."/>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            </div>

            <div className="ipad-hide">
                {/* Partners for Ipad */}
                <div className="container partners-padding">
                <div className="row">
                    <div className="col-md-12">
                        <p className="statistic-txt"><span>Partners</span></p>
                    </div>
                </div>
                <div className="row services-title-gap">
                    <div className="col-md-4">
                        <div className="card text-center parners-card">
                            <div className="card-body">
                                <img src={classify} className="img-fluid w-100 parners-logo" alt="..."/>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-4 sub-title-gap">
                        <div className="card text-center parners-card">
                            <div className="card-body">
                                <img src={ultra} className="img-fluid parners-logo" alt="..."/>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-4 sub-title-gap">
                        <div className="card text-center parners-card">
                            <div className="card-body">
                                <img src={gfi} className="img-fluid parners-logo2" alt="..."/>
                            </div>
                        </div>
                    </div>     
                </div>
                <div className="row">
                    <div className="col-md-4 sub-title-gap">
                        <div className="card text-center parners-card">
                            <div className="card-body">
                                <img src={cpf} className="img-fluid parners-logo3" alt="..."/>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-4 sub-title-gap">
                        <div className="card text-center parners-card">
                            <div className="card-body">
                                <img src={ultramart} className="img-fluid parners-logo" alt="..."/>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-4 sub-title-gap">
                        <div className="card text-center parners-card">
                            <div className="card-body">
                                <img src={rr} className="img-fluid parners-logo4" alt="..."/>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            </div>

            <div className="mobile-hide">
                {/* Partners for mobile*/}
                <div className="container partners-padding">
                    <div className="row">
                        <div className="col-md-12">
                            <p className="statistic-txt"><span>Partners</span></p>
                        </div>
                    </div>
                    <div className="row services-title-gap">
                        <div className="col-md-4 p-5">
                            <div className="card text-center parners-card">
                                <div className="card-body">
                                    <img src={classify} className="img-fluid parners-logo" alt="..."/>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-4 p-5 sub-title-gap">
                            <div className="card text-center parners-card">
                                <div className="card-body">
                                    <img src={ultra} className="img-fluid parners-logo2" alt="..."/>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-4 p-5 sub-title-gap">
                            <div className="card text-center parners-card">
                                <div className="card-body">
                                    <img src={gfi} className="img-fluid parners-logo2 parners-logo2-a" alt="..."/>
                                </div>
                            </div>
                        </div>     
                    </div>
                    <div className="row">
                        <div className="col-md-6 p-5 sub-title-gap">
                            <div className="card text-center parners-card">
                                <div className="card-body">
                                    <img src={ultramart} className="img-fluid parners-logo3" alt="..."/>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-6 p-5 sub-title-gap">
                            <div className="card text-center parners-card">
                                <div className="card-body">
                                    <img src={cpf} className="img-fluid parners-logo4" alt="..."/>
                                    &emsp;
                                    <img src={rr} className="img-fluid parners-logo5" alt="..."/>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* ----------------------------------------------------------------------------------------- */}
            

            
            {/* our products */}
            {/* ----------------------------------------------------------------------------------------- */}
            <div className="desktop-hide">
                {/* our products  for desktop*/}
                <div className="container-fluid our-products">
                    <div className="row"> 
                        <div className="col-md-6 op-padding">
                            <h2 className="product-title">Our Products</h2>
                            <p className="product-content">
                                It is a long established fact that a reader will be distracted by the readable
                                content of a page when looking at its layout. The point of using Lorem Ipsum is that it
                                has a more-or-less normal distribution of letters, as opposed to using 'Content here,
                                content here', making it look like readable English.
                            </p>
                        </div>
                        <div className="col-md-6">
                            <div className="row">
                                <div className="col-md-6 op-padding">
                                    <h3>
                                        <i className="fas fa-egg icon-color"></i> &nbsp;
                                        100% Egg Organic
                                    </h3>
                                    <p className="product-content">
                                        The point of using Lorem Ipsum is that it has a more-or-less normal distribution of letters,
                                        as opposed to using 'Content here, content here', making it look like readable English.
                                    </p>
                                </div>
                                <div className="col-md-6 op-padding">
                                    <h3>
                                        <i className="fas fa-egg"></i> &nbsp;
                                        100% Egg Organic
                                    </h3>
                                    <p className="product-content">
                                        The point of using Lorem Ipsum is that it has a more-or-less normal distribution of letters,
                                        as opposed to using 'Content here, content here', making it look like readable English.
                                    </p>
                                </div>
                                <div className="col-md-6 op-padding">
                                    <h3>
                                        <i className="fas fa-egg"></i> &nbsp;
                                        100% Egg Organic
                                    </h3>
                                    <p className="product-content">
                                        The point of using Lorem Ipsum is that it has a more-or-less normal distribution of letters,
                                        as opposed to using 'Content here, content here', making it look like readable English.
                                    </p>
                                </div>
                                <div className="col-md-6 op-padding">
                                    <h3>
                                        <i className="fas fa-clock"></i>&nbsp;
                                        24/7 Support Services
                                    </h3>
                                    <p className="product-content">
                                        The point of using Lorem Ipsum is that it has a more-or-less normal distribution of letters,
                                        as opposed to using 'Content here, content here', making it look like readable English.
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* products image for desktop*/}
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-md-12">
                            <div id="our-product" className="carousel slide" data-bs-touch="false" data-bs-interval="false">
                                <div className="carousel-inner">
                                    <div className="carousel-item active">
                                       
                                        <div className="product-grid">
                                            <div className="product-img-bg2"></div>
                                            <div className="image-title">
                                                <h1>Cows</h1>
                                            </div>
                                        </div>
                                        <div className="product-grid">
                                            <div className="product-img-bg3"></div>
                                            <div className="image-title">
                                                <h1>Chicken</h1>
                                            </div>
                                        </div>
                                        <div className="product-grid">
                                            <div className="product-img-bg4"></div>
                                            <div className="image-title">
                                                <h1>Poultry</h1>
                                            </div>
                                        </div>
                                        <div className="product-grid">
                                            <div className="product-img-bg5"></div>
                                            <div className="image-title">
                                                <h1>Goats</h1>
                                            </div>
                                        </div>
                                         <div className="product-grid">
                                            <div className="product-img-bg1"></div>
                                            <div className="image-title">
                                                <h1>Cows</h1>
                                            </div>
                                        </div>
                                    </div>
                                    {/* <div className="carousel-item">
                                        <div className="product-grid">
                                            <div className="product-img-bg1"></div>
                                            <div className="image-title">
                                                <h1>Poultry</h1>
                                            </div>
                                        </div>
                                        <div className="product-grid">
                                            <div className="product-img-bg2"></div>
                                            <div className="image-title">
                                                <h1>Poultry</h1>
                                            </div>
                                        </div>
                                        <div className="product-grid">
                                            <div className="product-img-bg3"></div>
                                            <div className="image-title">
                                                <h1>Poultry</h1>
                                            </div>
                                        </div>
                                        <div className="product-grid">
                                            <div className="product-img-bg4"></div>
                                            <div className="image-title">
                                                <h1>Poultry</h1>
                                            </div>
                                        </div>
                                        <div className="product-grid">
                                            <div className="product-img-bg5"></div>
                                            <div className="image-title">
                                                <h1>Poultry</h1>
                                            </div>
                                        </div>
                                    </div> */}
                                    {/* <div className="carousel-item">
                                        <div className="product-grid">
                                            <div className="product-img-bg1"></div>
                                            <div className="image-title">
                                                <h1>Poultry</h1>
                                            </div>
                                        </div>
                                        <div className="product-grid">
                                            <div className="product-img-bg2"></div>
                                            <div className="image-title">
                                                <h1>Poultry</h1>
                                            </div>
                                        </div>
                                        <div className="product-grid">
                                            <div className="product-img-bg3"></div>
                                            <div className="image-title">
                                                <h1>Poultry</h1>
                                            </div>
                                        </div>
                                        <div className="product-grid">
                                            <div className="product-img-bg4"></div>
                                            <div className="image-title">
                                                <h1>Poultry</h1>
                                            </div>
                                        </div>
                                        <div className="product-grid">
                                            <div className="product-img-bg5"></div>
                                            <div className="image-title">
                                                <h1>Poultry</h1>
                                            </div>
                                        </div>
                                    </div> */}
                                </div>
                                <br/><br/>
                                <div className="carousel-indicators">
                                    <button type="button" className="gallery-indicators active" data-bs-target="#our-product" data-bs-slide-to="0" aria-current="true" aria-label="Slide 1"></button>
                                    <button type="button" className="gallery-indicators" data-bs-target="#our-product" data-bs-slide-to="1" aria-label="Slide 2"></button>
                                    <button type="button" className="gallery-indicators" data-bs-target="#our-product" data-bs-slide-to="2" aria-label="Slide 3"></button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="ipad-hide">
                {/* our products content for IPad*/}


                <div className="container-fluid our-products">
                    <div className="row p-3"> 
                        <div className="col-md-12 op-padding">
                            <h2 className="product-title">Our Products</h2>
                            <p className="product-content">
                                It is a long established fact that a reader will be distracted by the readable
                                content of a page when looking at its layout. The point of using Lorem Ipsum is that it
                                has a more-or-less normal distribution of letters, as opposed to using 'Content here,
                                content here', making it look like readable English.
                            </p>
                        </div>
                    </div>

                    <div className="row p-3">
                        <div className="col-md-6 op-padding">
                            <h3>
                                <i className="fas fa-egg icon-color"></i> &nbsp;
                                100% Egg Organic
                            </h3>
                            <p className="product-content">
                                The point of using Lorem Ipsum is that it has a more-or-less normal distribution of letters,
                                as opposed to using 'Content here, content here', making it look like readable English.
                            </p>
                        </div>
                        <div className="col-md-6 op-padding">
                            <h3>
                                <i className="fas fa-egg"></i> &nbsp;
                                100% Egg Organic
                            </h3>
                            <p className="product-content">
                                The point of using Lorem Ipsum is that it has a more-or-less normal distribution of letters,
                                as opposed to using 'Content here, content here', making it look like readable English.
                            </p>
                        </div>
                    </div>
                    <div className="row p-3">
                        <div className="col-md-6 op-padding">
                            <h3>
                                <i className="fas fa-egg"></i> &nbsp;
                                100% Egg Organic
                            </h3>
                            <p className="product-content">
                                The point of using Lorem Ipsum is that it has a more-or-less normal distribution of letters,
                                as opposed to using 'Content here, content here', making it look like readable English.
                            </p>
                        </div>
                        <div className="col-md-6 op-padding">
                            <h3>
                                <i className="fas fa-clock"></i>&nbsp;
                                24/7 Support Services
                            </h3>
                            <p className="product-content">
                                The point of using Lorem Ipsum is that it has a more-or-less normal distribution of letters,
                                as opposed to using 'Content here, content here', making it look like readable English.
                            </p>
                        </div>
                    </div>
                </div>

                <div className="container-fluid">
                    {/* products images for IPad*/}
                    <div className="row">
                        <div className="col-md-12">
                            <div id="our2-product" className="carousel slide" data-bs-touch="false" data-bs-interval="false">
                                <div className="carousel-inner">
                                    <div className="carousel-item active">
                                        <div className="product-grid">
                                            <div className="product-img-bg1"></div>
                                            <div className="image-title">
                                                <h1>Cows</h1>
                                            </div>
                                        </div>
                                        <div className="product-grid">
                                            <div className="product-img-bg2"></div>
                                            <div className="image-title">
                                                <h1>Cows</h1>
                                            </div>
                                        </div>
                                        <div className="product-grid">
                                            <div className="product-img-bg3"></div>
                                            <div className="image-title">
                                                <h1>Chicken</h1>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="carousel-item">
                                        <div className="product-grid">
                                            <div className="product-img-bg2"></div>
                                            <div className="image-title">
                                                <h1>Poultry</h1>
                                            </div>
                                        </div>
                                        <div className="product-grid">
                                            <div className="product-img-bg3"></div>
                                            <div className="image-title">
                                                <h1>Goats</h1>
                                            </div>
                                        </div>
                                        <div className="product-grid">
                                            <div className="product-img-bg1"></div>
                                            <div className="image-title">
                                                <h1>Poultry</h1>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="carousel-item">
                                        <div className="product-grid">
                                            <div className="product-img-bg2"></div>
                                            <div className="image-title">
                                                <h1>Poultry</h1>
                                            </div>
                                        </div>
                                        <div className="product-grid">
                                            <div className="product-img-bg3"></div>
                                            <div className="image-title">
                                                <h1>Poultry</h1>
                                            </div>
                                        </div>
                                        <div className="product-grid">
                                            <div className="product-img-bg1"></div>
                                            <div className="image-title">
                                                <h1>Poultry</h1>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="carousel-item">
                                        <div className="product-grid">
                                            <div className="product-img-bg3"></div>
                                            <div className="image-title">
                                                <h1>Poultry</h1>
                                            </div>
                                        </div>
                                        <div className="product-grid">
                                            <div className="product-img-bg2"></div>
                                            <div className="image-title">
                                                <h1>Poultry</h1>
                                            </div>
                                        </div>
                                        <div className="product-grid">
                                            <div className="product-img-bg2"></div>
                                            <div className="image-title">
                                                <h1>Poultry</h1>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="carousel-item">
                                        <div className="product-grid">
                                            <div className="product-img-bg3"></div>
                                            <div className="image-title">
                                                <h1>Poultry</h1>
                                            </div>
                                        </div>
                                        <div className="product-grid">
                                            <div className="product-img-bg1"></div>
                                            <div className="image-title">
                                                <h1>Poultry</h1>
                                            </div>
                                        </div>
                                        <div className="product-grid">
                                            <div className="product-img-bg3"></div>
                                            <div className="image-title">
                                                <h1>Poultry</h1>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <br/><br/><br/>
                                <div className="carousel-indicators">
                                    <button className="carousel-control-prev5" type="button" data-bs-target="#our2-product" data-bs-slide="prev">
                                        <i className="fas fa-arrow-left fa-lg"></i>
                                        <span className="visually-hidden">Previous</span>
                                    </button> &emsp;&emsp; &emsp;&emsp; &emsp;&emsp; &emsp;&emsp;
                                    <button className="carousel-control-next5" type="button" data-bs-target="#our2-product" data-bs-slide="next">
                                        <i className="fas fa-arrow-right fa-lg"></i>
                                        <span className="visually-hidden">Next</span>
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>


            <div className="mobile-hide">
                <div className="container-fluid our-products1">
                    <div className="row"> 
                        <div className="col-md-6 op-padding">
                            <h2 className="product-title">Our Products</h2>
                            <p className="product-content">
                                It is a long established fact that a reader will be distracted by the readable
                                content of a page when looking at its layout. The point of using Lorem Ipsum is that it
                                has a more-or-less normal distribution of letters, as opposed to using 'Content here,
                                content here', making it look like readable English.
                            </p>
                        </div>
                        <div className="col-md-6">
                            <div className="row">
                                <div className="col-md-6 op-padding">
                                    <h3>
                                        <i className="fas fa-egg icon-color"></i> &nbsp;
                                        100% Egg Organic
                                    </h3>
                                    <p className="product-content">
                                        The point of using Lorem Ipsum is that it has a more-or-less normal distribution of letters,
                                        as opposed to using 'Content here, content here', making it look like readable English.
                                    </p>
                                </div>
                                <div className="col-md-6 op-padding">
                                    <h3>
                                        <i className="fas fa-egg"></i> &nbsp;
                                        100% Egg Organic
                                    </h3>
                                    <p className="product-content">
                                        The point of using Lorem Ipsum is that it has a more-or-less normal distribution of letters,
                                        as opposed to using 'Content here, content here', making it look like readable English.
                                    </p>
                                </div>
                                <div className="col-md-6 op-padding">
                                    <h3>
                                        <i className="fas fa-egg"></i> &nbsp;
                                        100% Egg Organic
                                    </h3>
                                    <p className="product-content">
                                        The point of using Lorem Ipsum is that it has a more-or-less normal distribution of letters,
                                        as opposed to using 'Content here, content here', making it look like readable English.
                                    </p>
                                </div>
                                <div className="col-md-6 op-padding">
                                    <h3>
                                        <i className="fas fa-clock"></i>&nbsp;
                                        24/7 Support Services
                                    </h3>
                                    <p className="product-content">
                                        The point of using Lorem Ipsum is that it has a more-or-less normal distribution of letters,
                                        as opposed to using 'Content here, content here', making it look like readable English.
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-md-12">
                            <div id="our1-product" className="carousel slide" data-bs-touch="false" data-bs-interval="false">
                                <div className="carousel-inner">
                                    <div className="carousel-item active">
                                        <div className="product-grid">
                                            <div className="product-img-bg1"></div>
                                            <div className="image-title">
                                                <h1>Cows</h1>
                                            </div>
                                        </div>
                                        <div className="product-grid">
                                            <div className="product-img-bg2"></div>
                                            <div className="image-title">
                                                <h1>Cows</h1>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="carousel-item">
                                        <div className="product-grid">
                                            <div className="product-img-bg1"></div>
                                            <div className="image-title">
                                                <h1>Cows</h1>
                                            </div>
                                        </div>
                                        <div className="product-grid">
                                            <div className="product-img-bg2"></div>
                                            <div className="image-title">
                                                <h1>Cows</h1>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="carousel-item">
                                        <div className="product-grid">
                                            <div className="product-img-bg1"></div>
                                            <div className="image-title">
                                                <h1>Cows</h1>
                                            </div>
                                        </div>
                                        <div className="product-grid">
                                            <div className="product-img-bg2"></div>
                                            <div className="image-title">
                                                <h1>Cows</h1>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <button className="carousel-control-prev3" type="button" data-bs-target="#our1-product" data-bs-slide="prev">
                                    <i className="fas fa-arrow-left fa-lg"></i>
                                    <span className="visually-hidden">Previous</span>
                                </button> &emsp;&emsp;
                                <button className="carousel-control-next3" type="button" data-bs-target="#our1-product" data-bs-slide="next">
                                    <i className="fas fa-arrow-right fa-lg"></i>
                                    <span className="visually-hidden">Next</span>
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* ----------------------------------------------------------------------------------------- */}


            {/* why choose nutrihofs*/}
            {/* ----------------------------------------------------------------------------------------- */}
            <div className="desktop-hide">
                {/* why choose nutrihofs for Desktop*/}
                <div className="container-fluid mt-5">
                    <div className="row choose-nutri">
                        <div className="col-md-12">
                            <p className="services-txt mobile-serv-txt">Why Choose Nutrihogs?</p>
                        </div>
                    </div>
                </div>
                {/*  why choose nutrihofs for Desktop Carousel */}
                <div id="carouselExampleFade" className="carousel slide" data-ride="carousel">
                <div className="carousel-inner choose-nutrihogs text-center">
                    <div className="carousel-item active">
                        <img src={img1} className="img-fluid" alt="..."/>
                        <div className="carousel-caption carousel-img-bg text-right">
                            <div className="carousel-img-text">
                                    <h5>Choose us. . .</h5>
                                    <p>
                                        The point of using Lorem Ipsum is that it has a more-or-less normal distribution of letters,
                                        as opposed to using 'Content here, content here', making it look like readable English.
                                    </p>
                            </div>
                        </div>
                    </div>
                    <div className="carousel-item">
                        <img src={img4} className="img-fluid" alt="..."/>
                        <div className="carousel-caption carousel-img-bg">
                            <div className="carousel-img-text">
                                <h5>Choose us 1. . .</h5>
                                <p>
                                    The point of using Lorem Ipsum is that it has a more-or-less normal distribution of letters,
                                    as opposed to using 'Content here, content here', making it look like readable English.
                                </p>
                            </div>
                        </div>
                    </div>
                    <div className="carousel-item">
                        <img src={img5} className="img-fluid" alt="..."/>
                        <div className="carousel-caption carousel-img-bg">
                            <div className="carousel-img-text">
                                <h5>Choose us 2. . .</h5>
                                <p>
                                    The point of using Lorem Ipsum is that it has a more-or-less normal distribution of letters,
                                    as opposed to using 'Content here, content here', making it look like readable English.
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
                <a className="carousel-control-prev" href="#carouselExampleFade" role="button" data-slide="prev">
                    <span className="carousel-control-prev-icon" aria-hidden="true"></span>
                    <span className="sr-only">Previous</span>
                </a>
                <a className="carousel-control-next" href="#carouselExampleFade" role="button" data-slide="next">
                    <span className="carousel-control-next-icon" aria-hidden="true"></span>
                    <span className="sr-only">Next</span>
                </a>
            </div>
            </div>

            <div className="ipad-hide">
                {/* why choose nutrihogs for ipad*/}
                <div className="container-fluid mt-5">
                    <div className="row choose-nutri">
                        <div className="col-md-12">
                            <p className="services-txt-ipad">Why Choose Nutrihogs?</p>
                        </div>
                    </div>
                </div>
                {/* why choose nutrihofs for Ipad Carousel */}
                <div id="carouselExampleFade2" className="carousel slide" data-ride="carousel">
                <div className="carousel-inner choose-nutrihogs text-center">
                    <div className="carousel-item active">
                        <img src={img1} className="img-fluid" alt="..."/>
                        <div className="carousel-caption carousel-img-bg text-right middle">
                            <div className="carousel-img-text">
                                    <h5>Choose us. . .</h5>
                                    <p>
                                        The point of using Lorem Ipsum is that it has a more-or-less normal distribution of letters,
                                        as opposed to using 'Content here, content here', making it look like readable English.
                                    </p>
                            </div>
                        </div>
                    </div>

                    <div className="carousel-item">
                        <img src={img4} className="img-fluid" alt="..."/>
                        <div className="carousel-caption carousel-img-bg middle">
                            <div className="carousel-img-text">
                                <h5>Choose us1. . .</h5>
                                <p>
                                    The point of using Lorem Ipsum is that it has a more-or-less normal distribution of letters,
                                    as opposed to using 'Content here, content here', making it look like readable English.
                                </p>
                            </div>
                        </div>
                    </div>
                    
                    <div className="carousel-item">
                        <img src={img5} className="img-fluid" alt="..."/>
                        <div className="carousel-caption carousel-img-bg middle">
                            <div className="carousel-img-text">
                                <h5>Choose us2. . .</h5>
                                <p>
                                    The point of using Lorem Ipsum is that it has a more-or-less normal distribution of letters,
                                    as opposed to using 'Content here, content here', making it look like readable English.
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
                <a className="carousel-control-prev" href="#carouselExampleFade2" role="button" data-slide="prev">
                    <span className="carousel-control-prev-icon" aria-hidden="true"></span>
                    <span className="sr-only">Previous</span>
                </a>
                <a className="carousel-control-next" href="#carouselExampleFade2" role="button" data-slide="next">
                    <span className="carousel-control-next-icon" aria-hidden="true"></span>
                    <span className="sr-only">Next</span>
                </a>
            </div>
            </div>

            <div className="mobile-hide">
                {/* why choose nutrihogs for mobile*/}
                <div className="container-fluid mt-5">
                    <div className="row choose-nutri">
                        <div className="col-md-12">
                            <p className="services-txt mobile-serv-txt">Why Choose Nutrihogs?</p>
                        </div>
                    </div>
                </div>
                {/* why choose nutrihofs for mobile Carousel */}
                <div id="carouselExampleFade" className="carousel slide" data-ride="carousel">
                <div className="carousel-inner choose-nutrihogs text-center">
                    <div className="carousel-item active">
                        <img src={img1} className="img-fluid" alt="..."/>
                        <div className="carousel-caption carousel-img-bg text-right middle">
                            <div className="carousel-img-text">
                                    <h5>Choose us. . .</h5>
                                    <p>
                                        The point of using Lorem Ipsum is that it has a more-or-less normal distribution of letters,
                                        as opposed to using 'Content here, content here', making it look like readable English.
                                    </p>
                            </div>
                        </div>
                    </div>
                    <div className="carousel-item">
                        <img src={img4} className="img-fluid" alt="..."/>
                        <div className="carousel-caption carousel-img-bg middle">
                            <div className="carousel-img-text">
                                <h5>Choose us. . .</h5>
                                <p>
                                    The point of using Lorem Ipsum is that it has a more-or-less normal distribution of letters,
                                    as opposed to using 'Content here, content here', making it look like readable English.
                                </p>
                            </div>
                        </div>
                    </div>
                    <div className="carousel-item">
                        <img src={img5} className="img-fluid" alt="..."/>
                        <div className="carousel-caption carousel-img-bg middle">
                            <div className="carousel-img-text">
                                <h5>Choose us. . .</h5>
                                <p>
                                    The point of using Lorem Ipsum is that it has a more-or-less normal distribution of letters,
                                    as opposed to using 'Content here, content here', making it look like readable English.
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
                <a className="carousel-control-prev" href="#carouselExampleFade" role="button" data-slide="prev">
                    <span className="carousel-control-prev-icon" aria-hidden="true"></span>
                    <span className="sr-only">Previous</span>
                </a>
                <a className="carousel-control-next" href="#carouselExampleFade" role="button" data-slide="next">
                    <span className="carousel-control-next-icon" aria-hidden="true"></span>
                    <span className="sr-only">Next</span>
                </a>
            </div>
            </div>

            {/* ----------------------------------------------------------------------------------------- */}


            {/* ----------------------------------------------------------------------------------------- */}

            {/* news */}
            <div className="container-fluid">
                <div className="row news-top">
                    <div className="col-md-12">
                        <p className="services-txt"><span>News</span></p>
                    </div>
                </div>

                <div className="desktop-hide">
                    {/* for desktop */}
                    <div className="row news-img-padding">
                    <div className="col-md-12">
                        <div id="news-slide" className="carousel slide" data-bs-touch="false" data-bs-interval="false">
                            <div className="carousel-inner">
                                <div className="carousel-item active team-left-margin">
                                    <div className="news-grid">
                                        <div className="news-img-plate news-img1"></div>
                                        <div className="news-name">
                                            <h4>March 05, 2021</h4>
                                            <p>CPFP SWINE BREEDER FARM AND NUTRIHOGS CORP. GROUND BREAKING CEREMONY</p>
                                        </div>
                                    </div>
                                    <div className="news-grid">
                                        <div className="news-img-plate news-img2"> </div>
                                        <div className="news-name">
                                            <h4>March 05, 2021</h4>
                                            <p>CPFP SWINE BREEDER FARM AND NUTRIHOGS CORP. GROUND BREAKING CEREMONY</p>
                                        </div>
                                    </div>
                                    <div className="news-grid">
                                        <div className="news-img-plate news-img3"> </div>
                                        <div className="news-name">
                                            <h4>March 05, 2021</h4>
                                            <p>CPFP SWINE BREEDER FARM AND NUTRIHOGS CORP. GROUND BREAKING CEREMONY</p>
                                        </div>
                                    </div>
                                    <div className="news-grid">
                                        <div className="news-img-plate news-img4"> </div>
                                        <div className="news-name">
                                            <h4>March 05, 2021</h4>
                                            <p>CPFP SWINE BREEDER FARM AND NUTRIHOGS CORP. GROUND BREAKING CEREMONY</p>
                                        </div>
                                    </div>
                                    <div className="news-grid">
                                        <div className="news-img-plate news-img4"> </div>
                                        <div className="news-name">
                                            <h4>March 05, 2021</h4>
                                            <p>CPFP SWINE BREEDER FARM AND NUTRIHOGS CORP. GROUND BREAKING CEREMONY</p>
                                        </div>
                                    </div>
                                </div>

                                <div className="carousel-item team-left-margin">
                                    <div className="news-grid">
                                        <div className="news-img-plate news-img4"> </div>
                                        <div className="news-name">
                                            <h4>March 05, 2021</h4>
                                            <p>CPFP SWINE BREEDER FARM AND NUTRIHOGS CORP. GROUND BREAKING CEREMONY</p>
                                        </div>
                                    </div>

                                    <div className="news-grid">
                                        <div className="news-img-plate news-img4"> </div>
                                        <div className="news-name">
                                            <h4>March 05, 2021</h4>
                                            <p>CPFP SWINE BREEDER FARM AND NUTRIHOGS CORP. GROUND BREAKING CEREMONY</p>
                                        </div>
                                    </div>
                                    <div className="news-grid">
                                        <div className="news-img-plate news-img4"> </div>
                                        <div className="news-name">
                                            <h4>March 05, 2021</h4>
                                            <p>CPFP SWINE BREEDER FARM AND NUTRIHOGS CORP. GROUND BREAKING CEREMONY</p>
                                        </div>
                                    </div>
                                    <div className="news-grid">
                                        <div className="news-img-plate news-img4"> </div>
                                        <div className="news-name">
                                            <h4>March 05, 2021</h4>
                                            <p>CPFP SWINE BREEDER FARM AND NUTRIHOGS CORP. GROUND BREAKING CEREMONY</p>
                                        </div>
                                    </div>
                                    <div className="news-grid">
                                        <div className="news-img-plate news-img4"> </div>
                                        <div className="news-name">
                                            <h4>March 05, 2021</h4>
                                            <p>CPFP SWINE BREEDER FARM AND NUTRIHOGS CORP. GROUND BREAKING CEREMONY</p>
                                        </div>
                                    </div>
                                </div>

                                <div className="carousel-item team-left-margin">
                                <div className="news-grid">
                                        <div className="news-img-plate news-img4"> </div>
                                        <div className="news-name">
                                            <h4>March 05, 2021</h4>
                                            <p>CPFP SWINE BREEDER FARM AND NUTRIHOGS CORP. GROUND BREAKING CEREMONY</p>
                                        </div>
                                    </div>

                                    <div className="news-grid">
                                        <div className="news-img-plate news-img4"> </div>
                                        <div className="news-name">
                                            <h4>March 05, 2021</h4>
                                            <p>CPFP SWINE BREEDER FARM AND NUTRIHOGS CORP. GROUND BREAKING CEREMONY</p>
                                        </div>
                                    </div>
                                    <div className="news-grid">
                                        <div className="news-img-plate news-img4"> </div>
                                        <div className="news-name">
                                            <h4>March 05, 2021</h4>
                                            <p>CPFP SWINE BREEDER FARM AND NUTRIHOGS CORP. GROUND BREAKING CEREMONY</p>
                                        </div>
                                    </div>
                                    <div className="news-grid">
                                        <div className="news-img-plate news-img4"> </div>
                                        <div className="news-name">
                                            <h4>March 05, 2021</h4>
                                            <p>CPFP SWINE BREEDER FARM AND NUTRIHOGS CORP. GROUND BREAKING CEREMONY</p>
                                        </div>
                                    </div>
                                    <div className="news-grid">
                                        <div className="news-img-plate news-img4"> </div>
                                        <div className="news-name">
                                            <h4>March 05, 2021</h4>
                                            <p>CPFP SWINE BREEDER FARM AND NUTRIHOGS CORP. GROUND BREAKING CEREMONY</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            {/* <button className="carousel-control-prev" type="button" data-bs-target="#services-slide" data-bs-slide="prev">
                                <span className="carousel-control-prev-icon" aria-hidden="true"></span>
                                <span className="visually-hidden">Previous</span>
                            </button>
                            <button className="carousel-control-next" type="button" data-bs-target="#services-slide" data-bs-slide="next">
                                <span className="carousel-control-next-icon" aria-hidden="true"></span>
                                <span className="visually-hidden">Next</span>
                            </button> */}
                            {/* <a className="carousel-control-prev" href="#carouselExampleFade" role="button" data-slide="prev">
                                <span className="carousel-control-prev-icon" aria-hidden="true"></span>
                                <span className="sr-only">Previous</span>
                            </a>
                            <a className="carousel-control-next" href="#carouselExampleFade" role="button" data-slide="next">
                                <span className="carousel-control-next-icon" aria-hidden="true"></span>
                                <span className="sr-only">Next</span>
                            </a> */}
                            <div className="carousel-indicators">
                                <button type="button" className="services-indicators active" data-bs-target="#news-slide" data-bs-slide-to="0" aria-current="true" aria-label="Slide 1"></button>
                                <button type="button" className="services-indicators" data-bs-target="#news-slide" data-bs-slide-to="1" aria-label="Slide 2"></button>
                                <button type="button" className="services-indicators" data-bs-target="#news-slide" data-bs-slide-to="2" aria-label="Slide 3"></button>
                            </div>
                        </div>                        
                    </div>
                </div>
                </div>
            
                <div className="ipad-hide">
                    {/* for ipad */}
                    <div className="row news-img-padding">
                    <div className="col-md-12">
                        <div id="news2-slide" className="carousel slide" data-bs-touch="false" data-bs-interval="false">
                            <div className="carousel-inner">
                                <div className="carousel-item active team-left-margin">
                                    <div className="news-grid">
                                        <div className="news-img-plate news-img1"></div>
                                        <div className="news-name">
                                            <h4>March 05, 2021</h4>
                                            <p>CPFP SWINE BREEDER FARM AND NUTRIHOGS CORP. GROUND BREAKING CEREMONY</p>
                                        </div>
                                    </div>
                                    <div className="news-grid">
                                        <div className="news-img-plate news-img2"> </div>
                                        <div className="news-name">
                                            <h4>March 05, 2021</h4>
                                            <p>CPFP SWINE BREEDER FARM AND NUTRIHOGS CORP. GROUND BREAKING CEREMONY</p>
                                        </div>
                                    </div>
                                    <div className="news-grid">
                                        <div className="news-img-plate news-img3"> </div>
                                        <div className="news-name">
                                            <h4>March 05, 2021</h4>
                                            <p>CPFP SWINE BREEDER FARM AND NUTRIHOGS CORP. GROUND BREAKING CEREMONY</p>
                                        </div>
                                    </div>
                                </div>

                                <div className="carousel-item team-left-margin">
                                    <div className="news-grid">
                                        <div className="news-img-plate news-img4"> </div>
                                        <div className="news-name">
                                            <h4>March 05, 2021</h4>
                                            <p>CPFP SWINE BREEDER FARM AND NUTRIHOGS CORP. GROUND BREAKING CEREMONY</p>
                                        </div>
                                    </div>
                                    <div className="news-grid">
                                        <div className="news-img-plate news-img4"> </div>
                                        <div className="news-name">
                                            <h4>March 05, 2021</h4>
                                            <p>CPFP SWINE BREEDER FARM AND NUTRIHOGS CORP. GROUND BREAKING CEREMONY</p>
                                        </div>
                                    </div>
                                    <div className="news-grid">
                                        <div className="news-img-plate news-img4"> </div>
                                        <div className="news-name">
                                            <h4>March 05, 2021</h4>
                                            <p>CPFP SWINE BREEDER FARM AND NUTRIHOGS CORP. GROUND BREAKING CEREMONY</p>
                                        </div>
                                    </div>
                                </div>

                                <div className="carousel-item team-left-margin">
                                    <div className="news-grid">
                                        <div className="news-img-plate news-img4"> </div>
                                        <div className="news-name">
                                            <h4>March 05, 2021</h4>
                                            <p>CPFP SWINE BREEDER FARM AND NUTRIHOGS CORP. GROUND BREAKING CEREMONY</p>
                                        </div>
                                    </div>
                                    <div className="news-grid">
                                        <div className="news-img-plate news-img4"> </div>
                                        <div className="news-name">
                                            <h4>March 05, 2021</h4>
                                            <p>CPFP SWINE BREEDER FARM AND NUTRIHOGS CORP. GROUND BREAKING CEREMONY</p>
                                        </div>
                                    </div>
                                    <div className="news-grid">
                                        <div className="news-img-plate news-img4"> </div>
                                        <div className="news-name">
                                            <h4>March 05, 2021</h4>
                                            <p>CPFP SWINE BREEDER FARM AND NUTRIHOGS CORP. GROUND BREAKING CEREMONY</p>
                                        </div>
                                    </div>
                                </div>
                                <div className="carousel-item team-left-margin">
                                    <div className="news-grid">
                                        <div className="news-img-plate news-img4"> </div>
                                        <div className="news-name">
                                            <h4>March 05, 2021</h4>
                                            <p>CPFP SWINE BREEDER FARM AND NUTRIHOGS CORP. GROUND BREAKING CEREMONY</p>
                                        </div>
                                    </div>
                                    <div className="news-grid">
                                        <div className="news-img-plate news-img4"> </div>
                                        <div className="news-name">
                                            <h4>March 05, 2021</h4>
                                            <p>CPFP SWINE BREEDER FARM AND NUTRIHOGS CORP. GROUND BREAKING CEREMONY</p>
                                        </div>
                                    </div>
                                    <div className="news-grid">
                                        <div className="news-img-plate news-img4"> </div>
                                        <div className="news-name">
                                            <h4>March 05, 2021</h4>
                                            <p>CPFP SWINE BREEDER FARM AND NUTRIHOGS CORP. GROUND BREAKING CEREMONY</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="carousel-indicators">
                                {/* <button type="button" className="services-indicators active" data-bs-target="#news-slide" data-bs-slide-to="0" aria-current="true" aria-label="Slide 1"></button>
                                <button type="button" className="services-indicators" data-bs-target="#news-slide" data-bs-slide-to="1" aria-label="Slide 2"></button>
                                <button type="button" className="services-indicators" data-bs-target="#news-slide" data-bs-slide-to="2" aria-label="Slide 3"></button> */}

                                <button className="carousel-control-prev5" type="button" data-bs-target="#news2-slide" data-bs-slide="prev">
                                    <i className="fas fa-arrow-left fa-lg"></i>
                                    <span className="visually-hidden">Previous</span>
                                </button> &emsp;&emsp; &emsp;&emsp; &emsp;&emsp; &emsp;&emsp;
                                <button className="carousel-control-next5" type="button" data-bs-target="#news2-slide" data-bs-slide="next">
                                    <i className="fas fa-arrow-right fa-lg"></i>
                                    <span className="visually-hidden">Next</span>
                                </button>
                            </div>
                        </div>                        
                    </div>
                </div>
                </div>
            
                <div className="mobile-hide">
                    {/* for mobile */}
                    <div className="row news-img-padding">
                    <div className="col-md-12">
                        <div id="news1-slide" className="carousel slide" data-bs-touch="false" data-bs-interval="false">
                            <div className="iphone-x">
                                <div className="carousel-inner">
                                    <div className="carousel-item active team-left-margin">
                                        <div className="news-grid">
                                            <div className="news-img-plate news-img1"></div>
                                            <div className="news-name">
                                                <h4>March 05, 2021</h4>
                                                <p>CPFP SWINE BREEDER FARM AND NUTRIHOGS CORP. GROUND BREAKING CEREMONY</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="carousel-item team-left-margin">
                                        <div className="news-grid">
                                            <div className="news-img-plate news-img2"> </div>
                                            <div className="news-name">
                                                <h4>March 05, 2021</h4>
                                                <p>CPFP SWINE BREEDER FARM AND NUTRIHOGS CORP. GROUND BREAKING CEREMONY</p>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="carousel-item team-left-margin">
                                        <div className="news-grid">
                                            <div className="news-img-plate news-img3"> </div>
                                            <div className="news-name">
                                                <h4>March 05, 2021</h4>
                                                <p>CPFP SWINE BREEDER FARM AND NUTRIHOGS CORP. GROUND BREAKING CEREMONY</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="carousel-item team-left-margin">
                                        <div className="news-grid">
                                            <div className="news-img-plate news-img4"> </div>
                                            <div className="news-name">
                                                <h4>March 05, 2021</h4>
                                                <p>CPFP SWINE BREEDER FARM AND NUTRIHOGS CORP. GROUND BREAKING CEREMONY</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <button className="carousel-control-prev4" type="button" data-bs-target="#news1-slide" data-bs-slide="prev">
                                <i className="fas fa-arrow-left fa-lg"></i>
                                <span className="visually-hidden">Previous</span>
                            </button> &emsp;&emsp;
                            <button className="carousel-control-next4" type="button" data-bs-target="#news1-slide" data-bs-slide="next">
                                <i className="fas fa-arrow-right fa-lg"></i>
                                <span className="visually-hidden">Next</span>
                            </button>
                        </div>                        
                    </div>
                </div>
                </div>
            </div>
        
            {/* ----------------------------------------------------------------------------------------- */}
        
        
        </div>
    )
}

export default IndexContent
