// import src from '*.avif'
import React from 'react'
import Header from '../Components/Header'
import Footer from '../Components/Footer'


// import about1 from '../assets/images/about-img1.png'
import vmimg1 from '../assets/images/vm-img1.png'
import vmimg2 from '../assets/images/vm-img2.png'
// import img1 from '../assets/images/img1.jpg'
// import img2 from '../assets/images/img2.jpg'
// import img3 from '../assets/images/img3.jpg'
// import img4 from '../assets/images/img4.jpg'
// import img5 from '../assets/images/img5.jpg'
// import img6 from '../assets/images/img6.jpg'
// import img7 from '../assets/images/img7.jpg'
// import img8 from '../assets/images/img8.png'
// import img9 from '../assets/images/img9.png'

import dummyprofile from '../assets/images/classify-profile.jpeg'
import reyan from '../assets/images/classify-reyan.jpg'
import carl from '../assets/images/classify-carl.jpg'
import jerwin from '../assets/images/classify-jerwin.jpg'
import nitish from '../assets/images/classify-nitish.jpg'
import joel from '../assets/images/classify-joel.jpg'
import kristine from '../assets/images/classify-kristine.jpg'
import alden from '../assets/images/classify-alden.jpg'


import classify from '../assets/images/classify.png'
import cpf from '../assets/images/cpf.png'
import gfi from '../assets/images/gfi.png'
import ultra from '../assets/images/ultra.png'
import ultramart2 from '../assets/images/ultramart.png'


const About = () => {
    return (
        <div className="">
            <Header />

            {/* for About us content ang image ------------------------------------------------------------ */}
            <div className="desktop-hide">
                {/* for Desktop */}
                <div className="container-fluid top">
                    <div className="row row-padding">
                        <div className="col-md-10 text-justify">
                            <h5 className="about-title text-dash"><span>About us</span></h5>
                            <p className="about-content">
                                It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. The point of using Lorem Ipsum is that it has a more-or-less normal distribution of letters, as opposed to using 'Content here, content here', making it look like readable English.
                            </p>
                        </div>
                        <div className="col-md-2"></div>
                    </div>
                </div>
                <div className="about-image">
                    <div className="green-bg"></div>
                </div>
            </div>

            <div className="ipad-hide">
                {/* for ipad */}
                <div className="container-fluid top">
                    <div className="row row-padding">
                        <div className="col-md-12 text-justify">
                            <h5 className="about-title text-dash"><span>About us</span></h5>
                            <p className="about-content">
                                It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. The point of using Lorem Ipsum is that it has a more-or-less normal distribution of letters, as opposed to using 'Content here, content here', making it look like readable English.
                            </p>
                        </div>
                    </div>
                </div>
                <div className="about-image">
                    <div className="green-bg"></div>
                </div>
            </div>
            
            <div className="mobile-hide">
                {/* for Mobile */}
                <div className="container-fluid top">
                    <div className="row row-padding">
                        <div className="col-md-10 text-justify">
                            <h5 className="about-title text-dash"><span>About us</span></h5>
                            <p className="about-content">
                                It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. The point of using Lorem Ipsum is that it has a more-or-less normal distribution of letters, as opposed to using 'Content here, content here', making it look like readable English.
                            </p>
                        </div>
                        <div className="col-md-2"></div>
                    </div>
                </div>
                <div className="about-image">
                    <div className="green-bg"></div>
                </div>
            </div>
            {/* for About us content ang image End -------------------------------------------------------- */}




            {/* for Mission and Vision ------------------------------------------------------------ */}

            <div className="desktop-hide">
                {/* Mission Vission (Page two) for desktop */}
                <div className="container">
                    <div className="row mv-row-padding">
                        <div className="col-md-6 text-justify middle padding-right">
                            <div>
                                <h5 className="mv-title">Vision and Mission</h5>
                                <p className="border-left text-paragrap1">
                                    The point of using Lorem Ipsum is that it has a more-or-less normal distribution of letters, as opposed to using 'Content here, content here', making it look like readable English. 
                                </p>
                                <p className="text-paragrap">
                                    It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. The point of using Lorem Ipsum is that it has a more-or-less normal distribution of letters, as opposed to using 'Content here, content here', making it look like readable English.  
                                </p>
                            </div>
                        </div>
                        <div className="col-md-3 middle">
                            <img src={vmimg1} alt="Santo Nino Logo" className="img-fluid"/>
                        </div>
                        <div className="col-md-3 middle" >
                            <img src={vmimg2} alt="Santo Nino Logo" className="img-fluid"/>
                        </div>
                    </div>
                </div>
            </div>

            
            <div className="ipad-hide">
                {/* for ipad */}
                <div className="container">
                <div className="row mv-row-padding">
                    <div className="col-md-12 text-justify middle padding-right">
                        <div>
                            <h5 className="mv-title">Vision and Mission</h5>
                            <p className="border-left">
                                The point of using Lorem Ipsum is that it has a more-or-less normal distribution of letters, as opposed to using 'Content here, content here', making it look like readable English. 
                            </p>
                            <p className="text-paragrap">
                                It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. The point of using Lorem Ipsum is that it has a more-or-less normal distribution of letters, as opposed to using 'Content here, content here', making it look like readable English.  
                            </p>
                        </div>
                    </div>
                </div>
                <div className="row vm-img">
                    <div className="col-md-6 middle">
                        <img src={vmimg1} alt="Santo Nino Logo" className="img-fluid"/>
                    </div>
                    <div className="col-md-6 middle" >
                        <img src={vmimg2} alt="Santo Nino Logo" className="img-fluid"/>
                    </div>
                </div>
            </div>
            </div>
            
            <div className="mobile-hide">
                {/* Mission Vission (Page two) for mobile */}
                <div className="container">
                    <div className="row mv-row-padding">
                        <div className="col-md-6 text-justify middle padding-right">
                            <div>
                                <h5 className="mv-title">Vision and Mission</h5>
                                <p className="border-left">
                                    The point of using Lorem Ipsum is that it has a more-or-less normal distribution of letters, as opposed to using 'Content here, content here', making it look like readable English. 
                                </p>
                                <p className="text-paragrap">
                                    It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. The point of using Lorem Ipsum is that it has a more-or-less normal distribution of letters, as opposed to using 'Content here, content here', making it look like readable English.  
                                </p>
                            </div>
                        </div>
                        <div className="col-md-3 middle">
                            <img src={vmimg1} alt="Santo Nino Logo" className="img-fluid"/>
                        </div>
                        <div className="col-md-3 middle" >
                            <img src={vmimg2} alt="Santo Nino Logo" className="img-fluid"/>
                        </div>
                    </div>
                </div>
            </div>
            {/* for A Mission and Vision End -------------------------------------------------------- */}




            {/* for Partners -------------------------------------------------------- */}
            <div className="desktop-hide">
                {/* partners */}
                <div className="container-fluid partner-bg">
                    <div className="container">
                        <div className="row partner-padding">
                            <div className="col-md-3 middle">
                                <h5 className="title">Partners:</h5>
                            </div>
                            <div className="col-md-9">
                                <div className="row">
                                    <div className="col-md-4">
                                        <img src={classify} alt="Santo Nino Logo" className="img-fluid partner-logo"/>
                                    </div>
                                    <div className="col-md-4">
                                        <img src={cpf} alt="Santo Nino Logo" className="img-fluid partner-logo1"/>
                                    </div>
                                    <div className="col-md-4">
                                        <img src={gfi} alt="Santo Nino Logo" className="img-fluid partner-logo"/>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-md-6">
                                        <img src={ultra} alt="Santo Nino Logo" className="img-fluid partner-logo"/>
                                    </div>
                                    <div className="col-md-6">
                                        <img src={ultramart2} alt="Santo Nino Logo" className="img-fluid partner-logo"/>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="ipad-hide">
                {/* for ipad */}
                <div className="container-fluid partner-bg">
                    <div className="container">
                        <div className="row partner-padding">
                            <div className="col-md-4 middle">
                                <h5 className="mv-title">Partners:</h5>
                            </div>
                            <div className="col-md-8">
                                <div className="row">
                                    <div className="col-md-4">
                                        <img src={classify} alt="Santo Nino Logo" className="img-fluid partner-logo"/>
                                    </div>
                                    <div className="col-md-4">
                                        <img src={cpf} alt="Santo Nino Logo" className="img-fluid partner-logo1"/>
                                    </div>
                                    <div className="col-md-4">
                                        <img src={gfi} alt="Santo Nino Logo" className="img-fluid partner-logo"/>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-md-6">
                                        <img src={ultra} alt="Santo Nino Logo" className="img-fluid partner-logo"/>
                                    </div>
                                    <div className="col-md-6">
                                        <img src={ultramart2} alt="Santo Nino Logo" className="img-fluid partner-logo"/>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="mobile-hide">
                {/* partners */}
                <div className="container-fluid partner-bg">
                    <div className="container">
                        <div className="row partner-padding">
                            <div className="col-md-3 middle">
                                <h5 className="title">Partners:</h5>
                            </div>
                            <div className="col-md-9">
                                <div className="row">
                                    <div className="col-md-4">
                                        <img src={classify} alt="Santo Nino Logo" className="img-fluid partner-logo"/>
                                    </div>
                                    <div className="col-md-4">
                                        <img src={cpf} alt="Santo Nino Logo" className="img-fluid partner-logo1"/>
                                    </div>
                                    <div className="col-md-4">
                                        <img src={gfi} alt="Santo Nino Logo" className="img-fluid partner-logo"/>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-md-6">
                                        <img src={ultra} alt="Santo Nino Logo" className="img-fluid partner-logo"/>
                                    </div>
                                    <div className="col-md-6">
                                        <img src={ultramart2} alt="Santo Nino Logo" className="img-fluid partner-logo"/>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* for Partners End -------------------------------------------------------- */}



            {/* Our Teams -------------------------------------------------------- */}
            
            <div className="container-fluid">
                <div className="desktop-hide">
                    {/* Our teams content for desktop */}
                    <div className="row team-padding">
                        <div className="col-md-10 text-justify">
                            <h5 className="about-title text-dash"><span>Our team</span></h5>
                            <p className="sub-title">Look back at our teams</p>
                            <p className="text-paragrap">
                                It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. The point of using Lorem Ipsum is that it has a more-or-less normal distribution of letters, as opposed to using 'Content here, content here', making it look like readable English.
                            </p>
                        </div>
                        <div className="col-md-2"></div>
                    </div>

                    {/* Our teams personel for desktop */}
                    <div className="row team-img-padding">
                        <div className="col-md-12">
                            <div id="team-slide" className="carousel slide" data-bs-touch="false" data-bs-interval="false">
                                <div className="carousel-inner">
                                    <div className="carousel-item active team-left-margin">
                                        <div className="our-team">
                                            <div className="team-img-plate team-img8"></div>
                                            <div className="team-name">
                                                <h4>Employee Name</h4>
                                                <p>Position</p>
                                            </div>
                                        </div>
                                        <div className="our-team">
                                            <div className="team-img-plate team-img8"> </div>
                                            <div className="team-name">
                                                <h4>Employee Name</h4>
                                                <p>Position</p>
                                            </div>
                                        </div>
                                        <div className="our-team">
                                            <div className="team-img-plate team-img8"> </div>
                                            <div className="team-name">
                                                <h4>Employee Name</h4>
                                                <p>Position</p>
                                            </div>
                                        </div>
                                        <div className="our-team">
                                            <div className="team-img-plate team-img8"> </div>
                                            <div className="team-name">
                                                <h4>Employee Name</h4>
                                                <p>Position</p>
                                            </div>
                                        </div>
                                        <div className="our-team">
                                            <div className="team-img-plate team-img8"> </div>
                                            <div className="team-name">
                                                <h4>Employee Name</h4>
                                                <p>Position</p>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="carousel-item team-left-margin">
                                        <div className="our-team">
                                            <div className="team-img-plate team-img8"> </div>
                                            <div className="team-name">
                                                <h4>Employee Name</h4>
                                                <p>Position</p>
                                            </div>
                                        </div>

                                        <div className="our-team">
                                            <div className="team-img-plate team-img8"> </div>
                                            <div className="team-name">
                                                <h4>Employee Name</h4>
                                                <p>Position</p>
                                            </div>
                                        </div>
                                        <div className="our-team">
                                            <div className="team-img-plate team-img8"> </div>
                                            <div className="team-name">
                                                <h4>Employee Name</h4>
                                                <p>Position</p>
                                            </div>
                                        </div>
                                        <div className="our-team">
                                            <div className="team-img-plate team-img8"> </div>
                                            <div className="team-name">
                                                <h4>Employee Name</h4>
                                                <p>Position</p>
                                            </div>
                                        </div>
                                        <div className="our-team">
                                            <div className="team-img-plate team-img8"> </div>
                                            <div className="team-name">
                                                <h4>Employee Name</h4>
                                                <p>Position</p>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="carousel-item team-left-margin">
                                        <div className="our-team">
                                            <div className="team-img-plate team-img8"> </div>
                                            <div className="team-name">
                                                <h4>Employee Name</h4>
                                                <p>Position</p>
                                            </div>
                                        </div>

                                        <div className="our-team">
                                            <div className="team-img-plate team-img8"> </div>
                                            <div className="team-name">
                                                <h4>Employee Name</h4>
                                                <p>Position</p>
                                            </div>
                                        </div>
                                        <div className="our-team">
                                            <div className="team-img-plate team-img8"> </div>
                                            <div className="team-name">
                                                <h4>Employee Name</h4>
                                                <p>Position</p>
                                            </div>
                                        </div>
                                        <div className="our-team">
                                            <div className="team-img-plate team-img8"> </div>
                                            <div className="team-name">
                                                <h4>Employee Name</h4>
                                                <p>Position</p>
                                            </div>
                                        </div>
                                        <div className="our-team">
                                            <div className="team-img-plate team-img8"> </div>
                                            <div className="team-name">
                                                <h4>Employee Name</h4>
                                                <p>Position</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="carousel-indicators">
                                    <button type="button" className="services-indicators active" data-bs-target="#team-slide" data-bs-slide-to="0" aria-current="true" aria-label="Slide 1"></button>
                                    <button type="button" className="services-indicators" data-bs-target="#team-slide" data-bs-slide-to="1" aria-label="Slide 2"></button>
                                    <button type="button" className="services-indicators" data-bs-target="#team-slide" data-bs-slide-to="2" aria-label="Slide 3"></button>
                                </div>
                            </div>                        
                        </div>
                    </div>
                </div>
                
                <div className="ipad-hide">
                    {/* Our teams content for desktop */}
                    <div className="row team-padding">
                        <div className="col-md-12 text-justify">
                            <h5 className="about-title text-dash"><span>Our team</span></h5>
                            <p className="sub-title">Look back at our teams</p>
                            <p className="text-paragrap">
                                It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. The point of using Lorem Ipsum is that it has a more-or-less normal distribution of letters, as opposed to using 'Content here, content here', making it look like readable English.
                            </p>
                        </div>
                    </div>

                    {/* Our teams personel for desktop */}
                    <div className="row team-img-padding">
                        <div className="col-md-12">
                            <div id="team2-slide" className="carousel slide" data-bs-touch="false" data-bs-interval="false">
                                <div className="carousel-inner">

                                    <div className="carousel-item active team-left-margin">
                                        <div className="our-team">
                                            <div className="team-img-plate team-img8"> </div>
                                            <div className="team-name">
                                                <h4>Employee Name</h4>
                                                <p>Position</p>
                                            </div>
                                        </div>

                                        <div className="our-team">
                                            <div className="team-img-plate team-img8"> </div>
                                            <div className="team-name">
                                                <h4>Employee Name</h4>
                                                <p>Position</p>
                                            </div>
                                        </div>

                                        <div className="our-team">
                                            <div className="team-img-plate team-img8"> </div>
                                            <div className="team-name">
                                                <h4>Employee Name</h4>
                                                <p>Position</p>
                                            </div>
                                        </div>
                                    </div>
                                    
                                    <div className="carousel-item team-left-margin">
                                        <div className="our-team">
                                            <div className="team-img-plate team-img8"> </div>
                                            <div className="team-name">
                                                <h4>Employee Name</h4>
                                                <p>Position</p>
                                            </div>
                                        </div>
                                        <div className="our-team">
                                            <div className="team-img-plate team-img8"> </div>
                                            <div className="team-name">
                                                <h4>Employee Name</h4>
                                                <p>Position</p>
                                            </div>
                                        </div>
                                        <div className="our-team">
                                            <div className="team-img-plate team-img8"> </div>
                                            <div className="team-name">
                                                <h4>Employee Name</h4>
                                                <p>Position</p>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="carousel-item team-left-margin">
                                        <div className="our-team">
                                            <div className="team-img-plate team-img8"> </div>
                                            <div className="team-name">
                                                <h4>Employee Name</h4>
                                                <p>Position</p>
                                            </div>
                                        </div>
                                        <div className="our-team">
                                            <div className="team-img-plate team-img8"> </div>
                                            <div className="team-name">
                                                <h4>Employee Name</h4>
                                                <p>Position</p>
                                            </div>
                                        </div>
                                        <div className="our-team">
                                            <div className="team-img-plate team-img8"> </div>
                                            <div className="team-name">
                                                <h4>Employee Name</h4>
                                                <p>Position</p>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="carousel-item team-left-margin">
                                        <div className="our-team">
                                            <div className="team-img-plate team-img8"> </div>
                                            <div className="team-name">
                                                <h4>Employee Name</h4>
                                                <p>Position</p>
                                            </div>
                                        </div>
                                        <div className="our-team">
                                            <div className="team-img-plate team-img8"> </div>
                                            <div className="team-name">
                                                <h4>Employee Name</h4>
                                                <p>Position</p>
                                            </div>
                                        </div>
                                        <div className="our-team">
                                            <div className="team-img-plate team-img8"> </div>
                                            <div className="team-name">
                                                <h4>Employee Name</h4>
                                                <p>Position</p>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="carousel-item team-left-margin">
                                        <div className="our-team">
                                            <div className="team-img-plate team-img8"> </div>
                                            <div className="team-name">
                                                <h4>Employee Name</h4>
                                                <p>Position</p>
                                            </div>
                                        </div>
                                        <div className="our-team">
                                            <div className="team-img-plate team-img8"> </div>
                                            <div className="team-name">
                                                <h4>Employee Name</h4>
                                                <p>Position</p>
                                            </div>
                                        </div>
                                        <div className="our-team">
                                            <div className="team-img-plate team-img8"> </div>
                                            <div className="team-name">
                                                <h4>Employee Name</h4>
                                                <p>Position</p>
                                            </div>
                                        </div>
                                    </div>
                                    
                                </div>
                                
                                <button className="carousel-control-prev" type="button" data-bs-target="#team2-slide" data-bs-slide="prev">
                                    <i className="fas fa-arrow-left fa-lg"></i>
                                    <span className="visually-hidden">Previous</span>
                                </button>
                                <button className="carousel-control-next" type="button" data-bs-target="#team2-slide" data-bs-slide="next">
                                    <i className="fas fa-arrow-right fa-lg"></i>
                                    <span className="visually-hidden">Next</span>
                                </button>
                            </div>                        
                        </div>
                    </div>
                </div>

                <div className="mobile-hide">
                    {/* Our teams content for mobile */}
                    <div className="row team-padding">
                        <div className="col-md-10 text-justify">
                            <h5 className="about-title text-dash"><span>Our team</span></h5>
                            <p className="sub-title">Look back at our teams</p>
                            <p className="text-paragrap">
                                It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. The point of using Lorem Ipsum is that it has a more-or-less normal distribution of letters, as opposed to using 'Content here, content here', making it look like readable English.
                            </p>
                        </div>
                        <div className="col-md-2"></div>
                    </div>
                    {/*  Our teams personel for mobile */}
                    <div className="row team-img-padding">
                        <div className="col-md-12">
                            <div id="team1-slide" className="carousel slide" data-bs-touch="false" data-bs-interval="false">
                                <div className="carousel-inner">
                                    <div className="carousel-item active team-left-margin">
                                        <div className="our-team">
                                            <div className="team-img-plate team-img8"> </div>
                                            <div className="team-name">
                                                <h4>Employee Name</h4>
                                                <p>Position</p>
                                            </div>
                                        </div>
                                        <div className="our-team">
                                            <div className="team-img-plate team-img8"> </div>
                                            <div className="team-name">
                                                <h4>Employee Name</h4>
                                                <p>Position</p>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="carousel-item team-left-margin">
                                        <div className="our-team">
                                            <div className="team-img-plate team-img8"> </div>
                                            <div className="team-name">
                                                <h4>Employee Name</h4>
                                                <p>Position</p>
                                            </div>
                                        </div>
                                        <div className="our-team">
                                            <div className="team-img-plate team-img8"> </div>
                                            <div className="team-name">
                                                <h4>Employee Name</h4>
                                                <p>Position</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="carousel-item team-left-margin">
                                        <div className="our-team">
                                            <div className="team-img-plate team-img8"> </div>
                                            <div className="team-name">
                                                <h4>Employee Name</h4>
                                                <p>Position</p>
                                            </div>
                                        </div>
                                        <div className="our-team">
                                            <div className="team-img-plate team-img8"> </div>
                                            <div className="team-name">
                                                <h4>Employee Name</h4>
                                                <p>Position</p>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="carousel-item team-left-margin">
                                        <div className="our-team">
                                            <div className="team-img-plate team-img8"> </div>
                                            <div className="team-name">
                                                <h4>Employee Name</h4>
                                                <p>Position</p>
                                            </div>
                                        </div>
                                        <div className="our-team">
                                            <div className="team-img-plate team-img8"> </div>
                                            <div className="team-name">
                                                <h4>Employee Name</h4>
                                                <p>Position</p>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="carousel-item team-left-margin">
                                        <div className="our-team">
                                            <div className="team-img-plate team-img8"> </div>
                                            <div className="team-name">
                                                <h4>Employee Name</h4>
                                                <p>Position</p>
                                            </div>
                                        </div>
                                        <div className="our-team">
                                            <div className="team-img-plate team-img8"> </div>
                                            <div className="team-name">
                                                <h4>Employee Name</h4>
                                                <p>Position</p>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="carousel-item team-left-margin">
                                        <div className="our-team">
                                            <div className="team-img-plate team-img8"> </div>
                                            <div className="team-name">
                                                <h4>Employee Name</h4>
                                                <p>Position</p>
                                            </div>
                                        </div>
                                        <div className="our-team">
                                            <div className="team-img-plate team-img8"> </div>
                                            <div className="team-name">
                                                <h4>Employee Name</h4>
                                                <p>Position</p>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="carousel-item team-left-margin">
                                        <div className="our-team">
                                            <div className="team-img-plate team-img8"> </div>
                                            <div className="team-name">
                                                <h4>Employee Name</h4>
                                                <p>Position</p>
                                            </div>
                                        </div>
                                        <div className="our-team">
                                            <div className="team-img-plate team-img8"> </div>
                                            <div className="team-name">
                                                <h4>Employee Name</h4>
                                                <p>Position</p>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="carousel-item team-left-margin">
                                        <div className="our-team">
                                            <div className="team-img-plate team-img8"> </div>
                                            <div className="team-name">
                                                <h4>Employee Name</h4>
                                                <p>Position</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                
                                <button className="carousel-control-prev" type="button" data-bs-target="#team1-slide" data-bs-slide="prev">
                                    <i className="fas fa-arrow-left fa-lg"></i>
                                    <span className="visually-hidden">Previous</span>
                                </button>
                                <button className="carousel-control-next" type="button" data-bs-target="#team1-slide" data-bs-slide="next">
                                    <i className="fas fa-arrow-right fa-lg"></i>
                                    <span className="visually-hidden">Next</span>
                                </button>
                            </div>                        
                        </div>
                    </div>
                </div>
            </div>

            {/* Our Teams end -------------------------------------------------------- */}



            {/* Gallery -------------------------------------------------------- */}

            <div className="desktop-hide">
                {/* Desktop Gallery */}
                <div className="container-fluid">
                    <div className="row gallery-padding">
                        <div className="col-md-12 text-justify">
                            <h5 className="about-title text-dash"><span>Gallery</span></h5>
                        </div>
                    </div>

                    <div className="row gallery-img-padding">
                        <div className="col-md-12">
                            <div id="gallery-slide" className="carousel slide" data-bs-touch="false" data-bs-interval="false">
                                <div className="carousel-inner">
                                    <div className="carousel-item active pb-5">
                                        <div className="gallery-image1">
                                            <div className="gallery-bg">
                                                {/* <p className="gallery-bg-title"> Farming </p> */}
                                            </div>
                                        </div>
                                        <div className="gallery-image2">
                                            <div className="gallery-bg">
                                                {/* <p className="gallery-bg-title1"> Poultry1 </p> */}
                                            </div>
                                        </div>
                                        <div className="gallery-image3">
                                            <div className="gallery-bg">
                                                {/* <p className="gallery-bg-title2"> Poultry2 </p> */}
                                            </div>
                                        </div>
                                        <div className="gallery-image4">
                                            <div className="gallery-bg">
                                                {/* <p className="gallery-bg-title3"> Poultry3</p> */}
                                            </div>
                                        </div>
                                        <div className="gallery-image6">
                                            <div className="gallery-bg">
                                                {/* <p className="gallery-bg-title1"> Poultry4</p> */}
                                            </div>
                                        </div>
                                        <div className="gallery-image5">
                                            <div className="gallery-bg">
                                                {/* <p className="gallery-bg-title"> Poultry5</p> */}
                                            </div>
                                        </div>
                                        <div className="gallery-image8">
                                            <div className="gallery-bg">
                                                {/* <p className="gallery-bg-title3"> Poultry6</p> */}
                                            </div>
                                        </div>
                                        <div className="gallery-image7">
                                            <div className="gallery-bg">
                                                {/* <p className="gallery-bg-title2"> Poultry7</p> */}
                                            </div>
                                        </div>
                                    </div>

                                    <div className="carousel-item pb-5">
                                        <div className="gallery-image1">
                                            <div className="gallery-bg">
                                                <p className="gallery-bg-title"> Farming </p>
                                            </div>
                                        </div>
                                        <div className="gallery-image2">
                                            <div className="gallery-bg">
                                                <p className="gallery-bg-title1"> Poultry1 </p>
                                            </div>
                                        </div>
                                        <div className="gallery-image3">
                                            <div className="gallery-bg">
                                                <p className="gallery-bg-title2"> Poultry2 </p>
                                            </div>
                                        </div>
                                        <div className="gallery-image4">
                                            <div className="gallery-bg">
                                                <p className="gallery-bg-title3"> Poultry3</p>
                                            </div>
                                        </div>
                                        <div className="gallery-image6">
                                            <div className="gallery-bg">
                                                <p className="gallery-bg-title1"> Poultry4</p>
                                            </div>
                                        </div>
                                        <div className="gallery-image5">
                                            <div className="gallery-bg">
                                                <p className="gallery-bg-title"> Poultry5</p>
                                            </div>
                                        </div>
                                        <div className="gallery-image8">
                                            <div className="gallery-bg">
                                                <p className="gallery-bg-title3"> Poultry6</p>
                                            </div>
                                        </div>
                                        <div className="gallery-image7">
                                            <div className="gallery-bg">
                                                <p className="gallery-bg-title2"> Poultry7</p>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="carousel-item pb-5">
                                        <div className="gallery-image1">
                                            <div className="gallery-bg">
                                                <p className="gallery-bg-title"> Farming </p>
                                            </div>
                                        </div>
                                        <div className="gallery-image2">
                                            <div className="gallery-bg">
                                                <p className="gallery-bg-title1"> Poultry1 </p>
                                            </div>
                                        </div>
                                        <div className="gallery-image3">
                                            <div className="gallery-bg">
                                                <p className="gallery-bg-title2"> Poultry2 </p>
                                            </div>
                                        </div>
                                        <div className="gallery-image4">
                                            <div className="gallery-bg">
                                                <p className="gallery-bg-title3"> Poultry3</p>
                                            </div>
                                        </div>
                                        <div className="gallery-image6">
                                            <div className="gallery-bg">
                                                <p className="gallery-bg-title1"> Poultry4</p>
                                            </div>
                                        </div>
                                        <div className="gallery-image5">
                                            <div className="gallery-bg">
                                                <p className="gallery-bg-title"> Poultry5</p>
                                            </div>
                                        </div>
                                        <div className="gallery-image8">
                                            <div className="gallery-bg">
                                                <p className="gallery-bg-title3"> Poultry6</p>
                                            </div>
                                        </div>
                                        <div className="gallery-image7">
                                            <div className="gallery-bg">
                                                <p className="gallery-bg-title2"> Poultry7</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="carousel-indicators">
                                    <button type="button" className="services-indicators active" data-bs-target="#gallery-slide" data-bs-slide-to="0" aria-current="true" aria-label="Slide 1"></button>
                                    <button type="button" className="services-indicators" data-bs-target="#gallery-slide" data-bs-slide-to="1" aria-label="Slide 2"></button>
                                    <button type="button" className="services-indicators" data-bs-target="#gallery-slide" data-bs-slide-to="2" aria-label="Slide 3"></button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="ipad-hide">
                {/* Ipad Gallery */}
                <div className="container-fluid">
                    <div className="row gallery-padding">
                        <div className="col-md-12 text-justify">
                            <h5 className="about-title text-dash"><span>Gallery</span></h5>
                        </div>
                    </div>

                    <div className="row gallery-img-padding">
                        <div className="col-md-12">
                            <div id="gallery2-slide" className="carousel slide" data-bs-touch="false" data-bs-interval="false">
                                <div className="carousel-inner">
                                    <div className="carousel-item active">
                                        <div className="gallery-image1">
                                            <div className="gallery-bg">
                                                <p className="gallery-bg-title"> Farming </p>
                                            </div>
                                        </div>
                                        <div className="gallery-image2">
                                            <div className="gallery-bg">
                                                <p className="gallery-bg-title1"> Poultry1 </p>
                                            </div>
                                        </div>
                                        <div className="gallery-image3">
                                            <div className="gallery-bg">
                                                <p className="gallery-bg-title2"> Poultry2 </p>
                                            </div>
                                        </div>
                                        <div className="gallery-image4">
                                            <div className="gallery-bg">
                                                <p className="gallery-bg-title1"> Poultry4</p>
                                            </div>
                                        </div>
                                        <div className="gallery-image5">
                                            <div className="gallery-bg">
                                                <p className="gallery-bg-title"> Poultry5</p>
                                            </div>
                                        </div>
                                        <div className="gallery-image6">
                                            <div className="gallery-bg">
                                                <p className="gallery-bg-title2"> Poultry7</p>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="carousel-item">
                                        <div className="gallery-image1">
                                            <div className="gallery-bg">
                                                <p className="gallery-bg-title"> Farming </p>
                                            </div>
                                        </div>
                                        <div className="gallery-image2">
                                            <div className="gallery-bg">
                                                <p className="gallery-bg-title1"> Poultry1 </p>
                                            </div>
                                        </div>
                                        <div className="gallery-image3">
                                            <div className="gallery-bg">
                                                <p className="gallery-bg-title2"> Poultry2 </p>
                                            </div>
                                        </div>
                                        <div className="gallery-image4">
                                            <div className="gallery-bg">
                                                <p className="gallery-bg-title1"> Poultry4</p>
                                            </div>
                                        </div>
                                        <div className="gallery-image5">
                                            <div className="gallery-bg">
                                                <p className="gallery-bg-title"> Poultry5</p>
                                            </div>
                                        </div>
                                        <div className="gallery-image6">
                                            <div className="gallery-bg">
                                                <p className="gallery-bg-title2"> Poultry7</p>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="carousel-item">

                                        <div className="gallery-image1">
                                            <div className="gallery-bg">
                                                <p className="gallery-bg-title"> Farming </p>
                                            </div>
                                        </div>
                                        <div className="gallery-image2">
                                            <div className="gallery-bg">
                                                <p className="gallery-bg-title1"> Poultry1 </p>
                                            </div>
                                        </div>
                                        <div className="gallery-image3">
                                            <div className="gallery-bg">
                                                <p className="gallery-bg-title2"> Poultry2 </p>
                                            </div>
                                        </div>
                                        <div className="gallery-image4">
                                            <div className="gallery-bg">
                                                <p className="gallery-bg-title1"> Poultry4</p>
                                            </div>
                                        </div>
                                        <div className="gallery-image5">
                                            <div className="gallery-bg">
                                                <p className="gallery-bg-title"> Poultry5</p>
                                            </div>
                                        </div>
                                        <div className="gallery-image6">
                                            <div className="gallery-bg">
                                                <p className="gallery-bg-title2"> Poultry7</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <button className="carousel-control-prev1" type="button" data-bs-target="#gallery2-slide" data-bs-slide="prev">
                                    <i className="fas fa-arrow-left fa-lg"></i>
                                    <span className="visually-hidden">Previous</span>
                                </button>
                                &emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;
                                <button className="carousel-control-next1" type="button" data-bs-target="#gallery2-slide" data-bs-slide="next">
                                    <i className="fas fa-arrow-right fa-lg"></i>
                                    <span className="visually-hidden">Next</span>
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="mobile-hide">
                {/* Mobile Gallery */}
                <div className="container-fluid">
                    <div className="row gallery-padding">
                        <div className="col-md-12 text-justify">
                            <h5 className="about-title text-dash mobile-title-top"><span>Gallery</span></h5>
                        </div>
                    </div>

                    <div className="row gallery-img-padding">
                        <div className="col-md-12">
                            <div id="gallery1-slide" className="carousel slide" data-bs-touch="false" data-bs-interval="false">
                                <div className="carousel-inner">
                                    <div className="carousel-item active">
                                        <div className="gallery-image1"></div>
                                        <div className="gallery-image2"></div>
                                        <div className="gallery-image2"></div>
                                        <div className="gallery-image1"></div>
                                    </div>
                                    <div className="carousel-item">
                                        <div className="gallery-image1"></div>
                                        <div className="gallery-image2"></div>
                                        <div className="gallery-image2"></div>
                                        <div className="gallery-image1"></div>
                                    </div>
                                </div>
                                <button className="carousel-control-prev1" type="button" data-bs-target="#gallery1-slide" data-bs-slide="prev">
                                    <i className="fas fa-arrow-left fa-lg"></i>
                                    <span className="visually-hidden">Previous</span>
                                </button>
                                &emsp;&emsp;&emsp;
                                <button className="carousel-control-next1" type="button" data-bs-target="#gallery1-slide" data-bs-slide="next">
                                    <i className="fas fa-arrow-right fa-lg"></i>
                                    <span className="visually-hidden">Next</span>
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            {/* Gallery End -------------------------------------------------------- */}

            <Footer />
        </div>
    )
}

export default About
