// import logo from './logo.svg';
import { BrowserRouter as Router, Route } from 'react-router-dom';
// css import
import './assets/css/App.css';

import './assets/css/Cont.css'; //for contact css style
import './assets/css/About.css'; //for contact css style
import './assets/css/style.css';

// file content in js
import Header from './Components/Header'
import Footer from './Components/Footer'
import IndexContent from './Components/IndexContent'
import about from './Components/About'
import contact from './Components/Contact'

function App() {
  
  return (
    <Router>
      <div className="App">
        <Route
          path='/'
          exact
          render={(props) => (
            <>
              <Header />
              <IndexContent />
              <Footer />
            </>
          )}
        />
        <Route path='/about' component={about} />
        <Route path='/contact' component={contact} />
      </div>
    </Router>
  );
}

export default App;
