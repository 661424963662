import React from 'react'
import Header from '../Components/Header'
import Footer from '../Components/Footer'
import ReCAPTCHA from 'react-google-recaptcha'


 

const Contact = () => {
    return (
        
        <div className="">
            
            <Header />
            <div className="container-fluid top">
                <div className="row cont-row-padding">

                    {/* contact details */}
                    <div className="col-md-6 middle-form-details col-right-padding">
                        <div className="card">
                            <div className="card-body">
                                <h5 className="card-title">Let’s get in <span>Touch</span></h5>
                                <h6 className="card-subtitle mb-2 text-muted">
                                    We're open for any Suggestion or just to have a chat
                                </h6>
                                <hr className="mt-5" />
                                    {/* <i className="fas fa-phone-alt fa-lg">
                                        &nbsp;<span>+63 907 093 8937</span>
                                    </i>
                                <hr className="mt-3" /> */}
                                    <i className="fas fa-blender-phone fa-lg">
                                        &nbsp;<span>(083) 225-3507</span>
                                    </i>
                                <hr className="mt-3" />
                                    <i className="fas fa-mail-bulk fa-lg">
                                        &nbsp;<span>accounting@nutrihogs.com.ph</span>
                                    </i>
                                <hr className="mt-3" />
                                    <i className="fas fa-map-marker-alt fa-lg">
                                        &nbsp;<span>Purok 8, Brgy. Glamang, Polomolok, South Cotabato 9504</span>
                                    </i>
                                <hr className="mt-3" />
                            </div>
                        </div>
                    </div>



                    {/* Form */}
                    <div className="col-md-6 col-left-padding">
                        <div className="form-bg p-5">
                            <form id="html_element">
                                <h5 className="form-title">Message us directly!</h5>
                                <div className="form-group mb-2">
                                    <label className="form-text" htmlFor="InputFullname">Fullname</label>
                                    <input type="text" className="form-control radius"/>
                                </div>

                                <div className="form-group mb-2">
                                    <label className="form-text" htmlFor="InputEmail1">Email address</label>
                                    <input type="email" className="form-control radius"/>
                                </div>
                                <div className="form-group">
                                    <label className="form-text" htmlFor="InputEmail1">Message</label>
                                    <textarea className="form-control radius" rows="4" cols="50"></textarea>
                                </div>
                                
                                <div className="g-recaptcha" data-sitekey="6LdlvasaAAAAAMUnOWgSZjDDLB4lSdzX8qBuvU8n"></div>
                                <div className="form-group mt-4">
                                    <button type="submit" className="btn form-control form-btn radius text-center">Submit</button>
                                     
                                </div>
                            </form>
                        </div>
                    </div>
                </div>


                
                {/* Location Map */}
                <div className="row map-padding">
                    <div className="col-md-12 h-50">
                        {/* <iframe className="radius iframe-size" src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3965.1245917758633!2d124.74761651426614!3d6.377913926609331!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x32f7f65f01d9fbe1%3A0x92935dd9febcdc8d!2sHoly%20Child%20College%20of%20Information%20Technology!5e0!3m2!1sen!2sph!4v1616057724555!5m2!1sen!2sph" allowFullScreen="" loading="lazy" title="This is a unique title"> </iframe> */}
                        <iframe className="radius iframe-size" src="https://maps.google.com/maps?width=100%25&amp;height=600&amp;hl=en&amp;q=Purok%208,%20Brgy.%20Glamang,%20Polomolok,%20South%20Cotabato%209504+(Nutrihogs)&amp;t=k&amp;z=14&amp;ie=UTF8&amp;iwloc=B&amp;output=embed" allowFullScreen="" loading="lazy" title="This is a unique title"> </iframe>
                    </div>
                </div>
            </div>

             
            <Footer />

            
        </div>

    )
}

export default Contact
 